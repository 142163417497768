@import "../../common/font";
@import "../../common/color";

div.patient-info-area {
  >div {
    overflow: visible;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    &:nth-child(1) {
      padding-right: 10px;
    }
    &:nth-child(2) {
      padding-left: 10px;
    }
  }

  &.modify {
    div.info-area > .content ul.list > li.row > div {
      height: auto;
      line-height: 40px;
      padding-bottom: 10px;
    }
  }

  div.info-area > .content {
    ul.list {
      padding: 20px 12px;

      > li.row {
        position: relative;
        clear: both;

        >div {
          padding: 0 8px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          height: 30px;
          line-height: 30px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          .resizableFont(14px);
          letter-spacing: -0.4px;

          input {
            width: 100%;
          }
          .radio-area {
            .radio-wrap {
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;

              >div {
                background: @white;
                border: 1px solid @gray5;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                border-radius: 20px;
              }

              &:nth-child(1) {
                padding-right: 5px;
              }
              &:nth-child(2) {
                padding-left: 5px;
              }
            }
          }

          &.title {
            color: @gray3;
          }
          &.info {
            color: @gray1;
          }
        }

        &.icon {
          div.info {
            padding-right: 32px;
          }
          a {
            position: absolute;
            display: block;
            top: 5px;
            right: 8px;
          }
        }
      }
    }
  }
  div.memo-area > .content {
    border: 0;
    padding: 0;
    height: 240px;

    textarea {
      display: block;
      resize: none;
    }
  }
  div.timeline-area {
    position: relative;
    min-height: 100%;

    >.content {
      min-height: 100%;
      border: 0;
      background: transparent;

      ul.timeline-content {
        padding-top: 13px;

        >li.item {
          padding: 7px 0 7px 41px;
          width: 100%;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;

          >.item-wrap {
            position: relative;
            width: 100%;
            min-height: 60px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            background-color: @white;
            border: solid 1px @gray5;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;

            &:before {
              position: absolute;
              display: block;
              left: -28px;
              top: 50%;
              width: 12px;
              height: 12px;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              -webkit-border-radius: 6px;
              -moz-border-radius: 6px;
              border-radius: 6px;
              -webkit-transform: translateY(-50%);
              -moz-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              -o-transform: translateY(-50%);
              transform: translateY(-50%);
              background-color: @white;
              border: solid 2px @brandMedium;
              content: ' ';
            }
          }

          h3 {
            .fontLight();
            display: block;
            width: 100%;
            padding: 14px 100px 8px 20px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            color: @gray1;
          }
          h4 {
            .fontLight();
            position: absolute;
            display: block;
            margin: 8px 14px 8px 0;
            width: 90px;
            color: @gray3;
            text-align: right;
            height: 44px;
            line-height: 44px;
            right: 0;
          }
          @media (max-width: 767px) {
            h4 {
              position: relative;
              text-align: left;
              padding-left: 20px;
              height: 20px;
              line-height: normal;
            }
            h3 {
              padding: 0 8px 8px 20px;
            }
          }
          span {
            .fontLight();
            display: block;
            padding: 0 14px 18px 20px;
            .resizableFont(14px);
            letter-spacing: -0.4px;
            color: @gray3;
            word-break: break-word;
          }

          &.large {
            h3 {
              height: 52px;
              padding-bottom: 0;
            }
            h4 {
              margin-bottom: 0
            }
          }
        }
      }

      &:before {
        position: absolute;
        display: block;
        width: 2px;
        height: 100%;
        top: 0;
        left: 19px;
        content: ' ';
        background: @gray5;
      }
    }
  }
}

@media (max-width: 1023px) {
  div.patient-info-area {
    > div {
      padding: 0 !important;
    }
    .group-box {
      margin-bottom: 20px;
    }
  }
}

div.patient-part-area {
  div.left-content-area {
    padding-right: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  div.right-content-area {
    padding-left: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  table {
    td {
      &:nth-child(1) {
        padding-left: 14px;
      }
      span {
        .fontLight();
        margin-right: 8px;
        .resizableFont(14px);
        letter-spacing: -0.4px;
        color: @gray1;
        line-height: 40px;
      }
    }
    th:nth-child(1) {
      padding-left: 14px;
    }
  }

  .surgery-area, .symptom-area {
    .accordion-item {
      position: relative;
      > label {
        height: 55px;
        > div {
          margin-top: 10px;
          margin-bottom: 10px;
          display: block;
          p.sub {
            display: block;
            font-size: 13px;
            color: @gray3;
          }
          p.main {
            display: block;
          }
        }
      }
      &.prime {
        border: 1px solid @brandLight;
        > label {
          background: fade(@brandLight, 20%);
        }
      }

      .title-button-area {
        float: right;
        position: absolute;
        top: 10px;
        right: 52px;
        > * {
          display: inline-block;
          margin-left: 2px;
        }
      }
    }
    table{
      tr {
        background: @gray6 !important;
        td {
          padding: 0 !important;
          border: none !important;
          div.accordion-content {
            line-height: 34px;
            li {
              width: 100%;
              margin: 18px 0;
              > * {
                display: inline-block;
              }
              >:nth-child(1) {
                vertical-align: top;
                p.sub {
                  line-height: 18px;
                  display: block;
                  font-size: 13px;
                  color: @gray3;
                }
                p.main {
                  line-height: 18px;
                  display: block;
                }
                p.prime {
                  font-size: 10px;
                }
                width: ~"calc(100% - 100px)";
              }
              >:nth-child(2) {
                width: 100px;
                button {
                  margin-left: 2px;
                }
              }
            }
          }
        }
      }
    }
  }

  .group-box {
    margin-bottom: 20px;
  }
}

@media (max-width: 1023px) {
  div.patient-part-area,
  div.left-content-area,
  div.right-content-area {
      padding: 0 !important;
  }
}

@media (max-width: 575px) {
  table {
    th:nth-child(2) {
      width: 100px;
    }
  }
}