@import "../common/constant";
@import "../common/font";
@import "../common/color";

.mypage-area .mypage-wrap {
  .group-box {
    margin-bottom: 40px;
  }

  .group-box.profile-info-area {
    width: 100%;
    @media (min-width: 768px) {
      position: absolute;
      width: 280px;
    }

    .profile-info-wrap {
      input[type=file] {
        display: none;
      }
      .profile-image {
        position: relative;
        margin: auto;
        width: 280px;
        height: 280px;
        overflow: hidden;
        border-radius: 5px;

        img {
          position: absolute;
          left:-10000%;
          right: -10000%;
          top: -10000%;
          bottom: -10000%;
          margin: auto;
          min-width: 1000%;
          min-height: 1000%;
          -webkit-transform: scale(0.1);
          -moz-transform: scale(0.1);
          -ms-transform: scale(0.1);
          -o-transform: scale(0.1);
          transform: scale(0.1);
        }
      }
      .action-group {
        position: relative;
        margin-top: 0;
        width: 100%;
        height: 0;
        line-height: 50px;
        text-align: center;
        background: fade(@black, 80%);
        -webkit-transition: all @animationNormal;
        -moz-transition: all @animationNormal;
        -ms-transition: all @animationNormal;
        -o-transition: all @animationNormal;
        transition: all @animationNormal;
        z-index: 1;
        overflow: hidden;

        div.action {
          .resizableFont(16px);
          color: @white;
          cursor: pointer;
        }
      }
      &:hover > .action-group {
        margin-top: -50px;
        height: 50px;
      }
    }
  }
  .group-box.history-info-area {
    padding-left: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    .history-info-wrap {
      ul.list {
        > li.item {
          margin-bottom: 8px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;

          .history {
            position: relative;

            .input-group {
              padding-right: 76px;

              > input {
                width: 100%;
                height: 40px;
              }
            }
            .button-group {
              position: absolute;
              top: 2px;
              right: 0;
              height: 40px;

              > button {
                margin: 0;
                line-height: 37px;
              }
            }
          }
        }
      }
    }
  }
  .group-box.basic-info-area {
    padding-right: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    > div.content {
      ul.list {
        li {
          > div {
            min-height: 30px;
            line-height: 30px;
            padding: 10px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;

            &:nth-child(1) {
              .resizableFont(12px);
              text-align: right;
              color: @gray2;
            }
            &:nth-child(2) {
              input, textarea {
                width: 100%;
              }
              textarea {
                height: 120px;
              }
            }
            button.combobox {
              width: 100%;
            }
            .input-box {
              > span.desc{
                .resizableFont(12px);
                color: @gray3;
              }
              > span.error {
                margin-top: -5px;
              }
              > div.combobox + span.error {
                margin-top: -10px;
              }
            }
            .radio-area {
              .radio-wrap {
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;

                > div {
                  background: @white;
                  border: 1px solid @gray5;
                  -webkit-border-radius: 20px;
                  -moz-border-radius: 20px;
                  border-radius: 20px;
                }

                &:nth-child(1) {
                  padding-right: 5px;
                }
                &:nth-child(2) {
                  padding-left: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}