@import "../common/color";

.part-view-container {
  >.part-view {
    float: left;
    width: 50%;
  }
}
.part-view {
  position: relative;

  .selector {
    position: absolute;
    width: 18%;
    height: 9%;
    border-radius: 50%;
    border: 2px solid @brandMedium;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    cursor: pointer;

    &.select {
      background: fade(@brandMedium, 30%);
      cursor: default;
    }
    &.disable {
      border-color: @gray1;
      background: fade(@gray1, 30%);
      pointer-events: none;
    }
  }

  img {
    width: 100%;
  }
}