@import "../common/font";
@import "../common/color";

#reservation-add-modal, #reservation-preset-add-modal {
  width: 960px;

  .content-container {
    position: relative;
    width: 100%;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: @white;

    .header-area {
      position: absolute;
      width: 100%;
      height: 60px;
      padding: 0 58px 0 20px;
      line-height: 60px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border-bottom: 1px solid @gray5;

      >.info-area {
        h3 {
          text-align: left;
          .resizableFont(16px);
          letter-spacing: 0;
          color: @gray1;
        }
      }
      >.close-area {
        position: absolute;
        right: 20px;
        top: 22px;
        cursor: pointer;

        i {
          display: block;
        }
      }
    }
    .body-area {
      width: 100%;
      min-height: 70vh;
      padding: 60px 0 0 0;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      >div {
        padding: 0;
        overflow-y: auto;

        > .group-box {
          padding-bottom: 20px;

          > div.title {
            position: relative;
            height: 30px;
            padding: 0 12px;

            &.big {
              height: 40px;
              > h2 {
                padding-right: 78px;
                line-height: 40px;
              }
            }

            > h2 {
              display: block;
              .resizableFont(12px);
              text-align: left;
              line-height: 30px;
              color: @gray3;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            > button {
              position: absolute;
              right: 10px;
              margin: 6px 0;
              width: 130px;
              height: 28px;
              line-height: 28px;
              -webkit-border-radius: 6px;
              -moz-border-radius: 6px;
              border-radius: 6px;
              background-color: @white;
              border: solid 1px @gray4;

              > h6 {
                height: 28px;
                line-height: 28px;
              }
            }
          }
        }
      }
      .result-area {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border-right: 1px solid @gray5;

        ul.schedule-list {
          width: 100%;
          padding: 0 10px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;

          >li {
            position: relative;
            line-height: 32px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
            background-color: @white;
            border: solid 1px @gray5;

            >h3 {
              float: left;
              display: block;
              margin: 5px 6px 0 8px;
              padding: 0;
              width: 38px;
              height: 22px;
              line-height: 22px;
              text-align: center;
              .resizableFont(12px);
              color: @white;
              background: @brandMedium;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              -webkit-border-radius: 11px;
              -moz-border-radius: 11px;
              border-radius: 11px;
            }
            >h4 {
              padding-left: 15px;
              display: block;
              padding-right: 30px;
              height: 32px;
              .resizableFont(12px);
              text-align: left;
              color: @gray2;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            >a {
              position: absolute;
              top: 11px;
              right: 10px;
              line-height: 0;
            }
          }
        }
        .input-group.preset {
          width: 100%;
          padding: 0 10px;

          input {
            width: 100%;
          }
        }
        .combobox.preset {
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          width: 100%;
          padding: 0 10px;
        }
        div.order-list {
          width: 100%;
          padding: 0 10px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;

          >.item {
            width: 100%;
            position: relative;
          }
        }
      }
      .questionnaire-area {
        padding: 0;
        overflow-y: scroll;

        div.list {
          .section {
            position: relative;
            padding: 0;
            width: 100%;
            -webkit-transition: all @animationFast;
            -moz-transition: all @animationFast;
            -ms-transition: all @animationFast;
            -o-transition: all @animationFast;
            transition: all @animationFast;

            i {
              position: absolute;
              top: 12px;
              right: 10px;
              -webkit-transform: rotate(180deg);
              -moz-transform: rotate(180deg);
              -ms-transform: rotate(180deg);
              -o-transform: rotate(180deg);
              transform: rotate(180deg);
              opacity: 0.4;
            }
            h2 {
              .fontRegular();
              display: block;
              .resizableFont(12px);
              padding: 0 32px 0 10px;
              height: 30px;
              line-height: 28px;
              color: @gray3;
              cursor: pointer;
              border-top: 1px solid @gray5;
              border-bottom: 1px solid @gray5;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
            }
            div.item-list {
              div.item {
                background-color: @white;
                cursor: pointer;

                .checkbox {
                  height: 30px;
                  line-height: 30px;

                  label {
                    .fontRegular();
                    .resizableFont(12px);
                  }
                }

                &:hover {
                  background-color: fade(@brandRegular, 10%);
                }
              }
            }
            &.hide {
              height: 30px;
              overflow: hidden;

              div.item-list {
                height: 0;
                overflow: hidden;
              }

              i {
                -webkit-transform: rotate(0);
                -moz-transform: rotate(0);
                -ms-transform: rotate(0);
                -o-transform: rotate(0);
                transform: rotate(0);
              }
            }
          }
        }
      }
    }
    .footer-area {
      border-top: 1px solid @gray5;
      padding-top: 14px;

      button {
        margin: 0;
      }
    }
  }
}
.reservation.order.item/*.gu-mirror*/ {
  line-height: 32px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: @white;
  border: solid 1px @gray5;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  >h4 {
    display: block;
    padding: 0 30px 0 12px;
    height: 32px;
    .resizableFont(12px);
    text-align: left;
    color: @gray2;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  >a {
    position: absolute;
    top: 8px;
    right: 5px;
    line-height: 0;
    padding: 5px;
    cursor: move;

    >i {
      pointer-events: none;
    }
  }
}

#reservation-schedule-add-modal, #ecrf-reservation-schedule-add-modal {
  .input-group {
    &.repeat,
    &.single {
      display: none;

      &.show {
        display: block;
      }
    }
  }

  .input-group {
    padding-bottom: 10px;

    label {
      color: @gray3;
    }
  }
  .input-box {
    padding: 0 10px;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    input {
      width: 100%;
    }
  }
  .filter-area {
    padding: 0 12px;

    > * {
      .fontRegular();
      float: left;
      padding: 0 4px;
      height: 40px;
      line-height: 40px;
      .resizableFont(14px);
      color: @gray1;
    }
  }
  ul.reservation-list {
    padding: 0 8px;

    li {
      height: 40px;
      > * {
        float: left;
        margin: 0 2px;
      }
      .button-area {
        margin-left: 6px;

        button {
          width: 34px;
          line-height: 34px;
        }
      }
      > *:nth-child(1) {
        width: ~"calc(100% - 90px)";
      }
      > *:nth-child(2) {
        width: 72px;
      }
    }
  }
}

#reservation-info-modal {
  .input-group ul.list {
    width: 100%;

    li.item {
      margin-bottom: 6px;
      padding: 0 12px;
      width: 100%;
      height: 34px;
      line-height: 32px;
      .resizableFont(12px);
      text-align: left;
      color: @gray2;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      background-color: @white;
      border: solid 1px @gray5;
    }
  }
}