.participant-progress-area {
  .progress-menu {
    position: absolute;
    height: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      border-bottom: 1px solid @brandLight;
      &.desc {
        margin-top: 60px;
        text-align: center;
        font-weight: bold;
        padding-bottom: 10px;
        > i {
          color: @gray2;
          .resizableFont(20px);
        }
      }
      a {
        .fontBold();
        display: block;
        width: 150px;
        padding: 25px 0;
        text-align: center;
        color: @brandMedium;
        position: relative;
        transition: background 0.3s ease-in-out;
        &:hover {
          background: @brandMedium;
          color: #fff;
        }
      }
      a.active {
        background: @brandMedium;
        color: #fff;
      }
      position: relative;
    }
  }
  .ecrf-form-area {
    margin-left: 180px;
    h1 {
      margin-bottom: 30px;
      padding-bottom: 10px;
      display: block;
      color: @black;
      text-align: center;
    }
    h1:after {
      position: relative;
      display: block;
      margin: 0 auto;
      width: 80px;
      height: 2px;
      background-color: @black;
      content: ' ';
    }
    .resend-preset-button {
      margin-top: 20px;
      display: block;
      text-align: center;
    }
    .upsert-button {
      display: block;
      text-align: center;
    }
    div .answer-box.metric {
      width: 150px;
      display: inline-block;
      .metric-value {
        font-weight: 300;
      }
    }
  }
}