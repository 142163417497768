@import "constant";

.iconSingle(@name, @file, @width, @height) {
  .iconSprite(@name, @file, @width, @height, false, false, false, false, 0, 0, false);
}
.iconSprite(@name, @file, @width, @height) {
  .iconSprite(@name, @file, @width, @height, false, false, false, false, 0, 0, true);
}
.iconSprite(@name, @file, @width, @height, @useHover, @useActive) {
  .iconSprite(@name, @file, @width, @height, @useHover, @useActive, false, false, 0, 0, true);
}
.iconSprite(@name, @file, @width, @height, @useHover, @useActive, @useOffsetX, @useOffsetY, @offsetX, @offsetY, @useSprite) {
  /// Normal
  i.ps {
    &.ps-@{name} {
      background: url("@{imagePath}/@{file}.png") 0 0 no-repeat;
      background-size: @width @height;
      & when (@useSprite = true) {
        width: calc(@width / 3);
      }
      & when (@useSprite = false) {
        width: @width;
      }
      height: @height;

      & when (@useOffsetX = true) {
        margin-left: @offsetX;
      }
      & when (@useOffsetY = true) {
        margin-bottom: calc(-@offsetY);
      }
    }
  }
  i.ps.ps-@{name}.ps-fix, i.ps.ps-@{name}.ps-fix.active, .active > i.ps.ps-@{name}.ps-fix, a:active > i.ps.ps-@{name}.ps-fix, button:active > i.ps.ps-@{name}.ps-fix {
    background: url("@{imagePath}/@{file}.png") 0 0 no-repeat !important;
    background-size: @width @height !important;
    & when (@useSprite = true) {
      width: calc(@width / 3) !important;
    }
    & when (@useSprite = false) {
      width: @width !important;
    }
    height: @height !important;
  }
  /// 2x
  body.double i.ps {
    &.ps-@{name} {
      background-image: url("@{imagePath}/@{file}@2x.png");
    }
  }
  /// 3x
  body.triple i.ps {
    &.ps-@{name} {
      background-image: url("@{imagePath}/@{file}@3x.png");
    }
  }
  /// svg
  body.svg i.ps {
    &.ps-@{name} {
      background-image: url("@{imagePath}/@{file}.svg");
    }
  }

  & when (@useSprite = true) {
    /// Hover
    & when (@useHover = true) {
      i.ps:hover, a:hover > i.ps, button:hover > i.ps {
        &.ps-@{name} {
          background-position: calc(@width / 3 * -1) 0;
        }
      }
    }

    /// Active
    & when (@useActive = true) {
      i.ps.active, .active i.ps, a:active > i.ps, button:active > i.ps {
        &.ps-@{name} {
          background-position: calc(@width / 3 * -2) 0 !important;
        }
      }
    }

    i.ps:disabled, a:disabled > i.ps, button:disabled > i.ps {
      &.ps-@{name} {
        background-position: 0 0 !important;
      }
    }
  }
}

i.ps {
  display: inline-block;
  -webkit-transition: opacity @animationFast;
  -moz-transition: opacity @animationFast;
  -ms-transition: opacity @animationFast;
  -o-transition: opacity @animationFast;
  transition: opacity @animationFast;
}

i.fa-menu {
  .resizableFont(18px);
  width: 18px;
  color: #dfe1e3;
  text-align: center;
}
i.far.active, .active i.far, a:active > i.far, button:active > i.far,
i.fas.active, .active i.fas, a:active > i.fas, button:active > i.fas {
  color: @brandRegular
}
.iconSingle(company-icon, "icon-mddatasolution", 70px, 50px);
.iconSingle(brand-large, "icon-brand-large", 186px, 32px);
.iconSingle(brand-middle, "icon-brand-middle", 130px, 20px);
.iconSingle(brand-icon, "icon-brand", 36px, 40px);
.iconSingle(brand-text, "icon-brand-text", 137px, 18px);
.ecrf {
  @media screen and (min-width: 1023px) {
      .iconSingle(brand-text, "ecrf/icon-brand-text", 137px, 37px) !important;
      .iconSingle(brand-large, "ecrf/icon-brand-large", 186px, 50px) !important;
  }
  .iconSingle(brand-middle, "ecrf/icon-brand-middle", 169px, 20px) !important;
}
.iconSprite(menu-dashboard, "icon-menu-dashboard", 48px, 16px, true, true, false, true, 0, 1px, true);
.iconSprite(menu-patient, "icon-menu-user", 48px, 16px, true, true, false, true, 0, 1px, true);
.iconSprite(menu-folder, "icon-menu-folder", 48px, 16px, true, true, false, true, 0, 1px, true);
.iconSprite(menu-notice, "icon-menu-notice", 48px, 16px, true, true, false, true, 0, 1px, true);
.iconSprite(menu-mypage, "icon-menu-mypage", 48px, 16px, true, true, false, true, 0, 1px, true);
.iconSprite(menu-logout, "icon-menu-logout", 48px, 16px, true, true, false, true, 0, 1px, true);
.iconSprite(menu-preset, "icon-menu-preset", 48px, 16px, true, true, false, true, 0, 1px, true);
.iconSprite(hamburger, "icon-hamburger", 54px, 16px, true, true);
.iconSprite(notification, "icon-notification", 66px, 20px, true, true);
.iconSingle(down-open, "icon-down-open", 8px, 4px);
.iconSingle(left-open, "icon-left-open", 6px, 10px);
.iconSingle(right-open, "icon-right-open", 6px, 10px);
.iconSprite(plus, "icon-plus", 36px, 12px, true, true, false, true, 0, -1px, true);
.iconSprite(down-triangle, "icon-down-triangle", 30px, 5px, true, true);
.iconSprite(folder, "icon-folder", 60px, 16px, true, true);
.iconSprite(folder-small, "icon-folder-small", 48px, 16px, true, true, false, true, 0, 1px, true);
.iconSingle(foldercell-normal, "icon-foldercell-normal", 20px, 18px);
.iconSingle(foldercell-share, "icon-foldercell-share", 23px, 18px);
.iconSprite(graph, "icon-graph", 60px, 16px, true, true);
.iconSprite(graph-small, "icon-graph-small", 48px, 16px, true, true, false, true, 0, 1px, true);
.iconSprite(download, "icon-download", 60px, 18px, true, true);
.iconSingle(search, "icon-search", 16px, 16px);
.iconSingle(user, "icon-user", 20px, 18px);
.iconSingle(info, "icon-info", 18px, 18px);
.iconSingle(leave, "icon-leave", 12px, 12px);
.iconSingle(close, "icon-close", 16px, 16px);
.iconSprite(share, "icon-share", 36px, 12px, true, true);
.iconSprite(modify, "icon-modify", 42px, 14px, true, true);
.iconSprite(comment, "icon-comment", 42px, 14px, true, true);
.iconSprite(delete, "icon-delete", 36px, 16px, true, true);
.iconSprite(input-bag, "icon-input-bag", 48px, 16px);
.iconSprite(input-company, "icon-input-company", 48px, 16px);
.iconSprite(input-email, "icon-input-email", 48px, 16px);
.iconSprite(input-password, "icon-input-password", 48px, 16px);
.iconSprite(input-phone, "icon-input-phone", 48px, 16px);
.iconSprite(input-user, "icon-input-user", 48px, 16px);
.iconSingle(lock, "icon-lock", 32px, 48px);
.iconSingle(drag, "icon-drag", 10px, 6px);
.iconSingle(remove, "icon-remove", 10px, 10px);
