body.ecrf {
  div.info-area > .content {
    padding: 10px;
    ul.list {
      padding: 20px 12px;

      > li.row {
        position: relative;
        clear: both;

        > div {
          padding: 0 8px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          margin: 7px 0;
          .resizableFont(16px);
          letter-spacing: -0.4px;

          &.title {
            color: @gray3;
          }

          &.info {
            color: @gray1;
          }
        }
      }
    }
  }
}
