@import "../common/font";
@import "../common/color";

#adaptment-modify-modal{
  div.body-area {
    .result {
      margin: 0 auto 30px;
      text-align: center;
      border: 1px solid @gray4;
      padding: 10px 30px;
      border-radius: 10px;
      h3 {
        font-weight: 400;
        color: @gray2;
      }
      .respond-text {
        .resizableFont(15px);
        margin-top: 20px;
      }
    }
    .input-group {
      margin-bottom: 30px;
    }
  }
}