.body-container {
  height: 100vh;
  overflow: auto;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;

  .header-container {
    width: 100%;
    height: 72px;
    vertical-align: middle;
    line-height: 72px;

    >.left-area {
      padding: 0 28px;
      box-sizing: border-box;
    }
    >.right-area {
      text-align: right;
      padding: 0 46px;
      box-sizing: border-box;

      > * {
        float: right;
        padding: 0 10px;
      }
    }
    .notification-area {
      display: inline-block;
      margin-top: 5px;
    }
    .user-area {
      cursor: pointer;

      .user-wrap {
        >h4 {
          letter-spacing: 0.5px;
        }
        >i {
          margin-left: 8px;
          margin-bottom: 3px;
        }
      }
    }
  }
}

.notification-area > .notification-wrap {
  position: relative;
  width: 23px;
  height: 22px;

  span.dot {
    position: absolute;
    display: block;
    top: -5px;
    right: 0;
    width: 8px;
    height: 8px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background: @brandMedium;
  }
}