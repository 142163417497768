@import "constant";
@import "color";

body {
  display: none;
  margin: 0;
  padding: 0;
  background: @gray6;

  &.load {
    display: inherit !important;
  }
}

ul, li, ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.clearfix {
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  clear: both;
}

a {
  text-decoration: none;
  color: @black;
  outline: none;

  -webkit-transition: color @animationFast;
  -moz-transition: color @animationFast;
  -ms-transition: color @animationFast;
  -o-transition: color @animationFast;
  transition: color @animationFast;

  &:hover, &:active {
    color: @brandMedium;
  }
  &.disabled {
    pointer-events: none;
    cursor: default;
  }
}

.group-box {
  >h4 {
    position: relative;
    display: block;
    padding: 0 2px 2px;
    width: 100%;
    height: 34px;
    line-height: 34px;
    .resizableFont(15px);
    text-align: left;
    color: @gray3;
    background: @gray6;
    z-index: 1;
  }
  >h5 {
    position: relative;
    display: block;
    padding: 0 12px 2px;
    width: 100%;
    margin-top: -16px;
    height: 30px;
    line-height: 30px;
    .resizableFont(12px);
    text-align: left;
    color: @gray3;
    z-index: 1;
  }
  >div.content {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background-color: @white;
    border: solid 1px @gray5;
  }
  >div.content.empty {
    padding: 10px;
    text-align: center;
    font-weight: 300;
  }
  >div.content.empty:before {
    content: "노출할 데이터가 없습니다";
  }

  &.in-header {
    >h4, >div.action {
      float: left;
      width: 50%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
    >div.action {
      position: relative;
      text-align: right;
      z-index: 1;
    }
    >div.content {
      clear: both;
    }
  }
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 1023px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1279px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1280px) {
  .hidden-xl-up {
    display: none !important;
  }
}
.hidden-xl-down {
  display: none !important;
}

.container {
  height: 100vh;

  >.sidebar-container {
    display: flex;
    flex-direction: column;

    position: fixed;
    left: 0;
    width: 212px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: @black;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: left @animationSlow;
    -moz-transition: left @animationSlow;
    -ms-transition: left @animationSlow;
    -o-transition: left @animationSlow;
    transition: left @animationSlow;

    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  >.body-container {
    margin-left: 200px;
    -webkit-transition: margin @animationSlow;
    -moz-transition: margin @animationSlow;
    -ms-transition: margin @animationSlow;
    -o-transition: margin @animationSlow;
    transition: margin @animationSlow;

    .content-container {
      padding: 18px 60px;

      .content-header-container > div {
        position: relative;

        h1 > span {
          color: @gray3
        }

        .page-action-area {
          position: absolute;
          bottom: 0;
          right: 0;
          button, form {
            display: inline-block;
          }
        }
      }
      .content-body-container {
        margin-top: 40px;
      }
    }
  }
  &.hide-sidebar {
    >.sidebar-container {
      left: -190px;
    }
    >.body-container {
      margin-left: 10px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .container {
    >.sidebar-container {
      left: 0 !important;
      width: 100% !important;
      height: 44px;
      overflow: hidden;
      z-index: 100;
    }
    >.body-container {
      margin-left: 0;
      padding-top: 44px;

      .content-container {
        padding: 40px 20px;

        .content-header-container > div {
          position: inherit;
          margin-top: 14px;

          .page-action-area {
            position: inherit;
            bottom: auto;
            right: auto;
          }
        }
      }
    }
  }
}
