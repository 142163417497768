@import "../common/constant";
@import "../common/color";

button {
  margin: 0 0 6px 0;
  padding: 0 12px;
  height: 34px;
  line-height: 34px;
  background-color: @white;
  border: solid 1px @gray4;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: left;

  h6 {
    height: 34px;
    line-height: 34px;
    color: @gray1;
    text-align: left;
    margin-top: -1px;
    display: inline-block;
    vertical-align: top;
  }
  i.far, i.fas {
    position: relative;
    color: @gray1;
  }
  i + h6 {
    margin-left: 6px;
  }

  &:hover, &.hover {
    border-color: @brandMedium !important;
    i, h6 {
      color: @brandMedium;
    }
  }
  &:active, &.active, &.hover:active, &.hover.active {
    border-color: @brandMedium !important;;
    background: @brandMedium !important;
    i, h6 {
      color: @white !important;
    }
  }
  &:disabled, &[readonly] {
    background: @gray3 !important;
    border-color: @gray3 !important;
    i, h6 {
      color: @white !important;
    }
  }

  &.small {
    padding: 0;
    line-height: 40px;
    width: 34px;
    text-align: center;
  }
  &.text {
    text-align: center;
  }
  &.tag {
    padding: 0 12px;
    width: auto !important;
    height: 24px;
    line-height: 24px;

    h6 {
      height: 24px;
      line-height: 24px;
    }
  }
}
