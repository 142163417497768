@import "../common/font";
@import "../common/color";
#randomization-result-modal{
  div.header-area {
    h1.title {
      display: block;
      width: 100%;
      line-height: 50px;
      padding: 0 20px;
      margin-bottom: 6px;
      text-align: center;
      color: @gray1;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }

    > .prev-area {
      position: absolute;
      top: 14px;
      left: 20px;
    }
    > .close-area {
      position: absolute;
      right: 20px;
      top: 22px;
      cursor: pointer;

      i {
        display: block;
      }
    }
  }
  div.body-area {
    p {
      &.desc {
        font-weight: 300;
        display: block;
        width: 100%;
        min-height: 32px;
        padding: 0 20px;
        margin-bottom: 6px;
        text-align: center;
        color: @gray1;
        .resizableFont(14px);
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        span {
          .fontBold();
        }
      }
      &.title {
        display: block;
        width: 100%;
        height: 50px;
        line-height: 50px;
        padding: 0 20px;
        margin-bottom: 6px;
        text-align: center;
        color: @gray1;
        .resizableFont(16px);
        font-weight: 600;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        margin-top: 30px;
      }
    }
    #result {
      margin-bottom: 30px;
      button {
        display: block;
        width: fit-content;
        text-align: center;
        margin: 0 auto;
        &.disabled {
          cursor: default;
        }
        &.disabled:hover:not(.selected), &.disabled:active:not(.selected) {
          border: 1px solid @gray4 !important;
          background: @white !important;
          > h6 {
            color: @gray1 !important;
          }
        }
        &.selected {
          background: @brandMedium;
          border: @brandMedium;
          > h6 {
            color: @white
          }
        }
      }
    }
  }
}



#processing {

  * {
    box-sizing: border-box;
  }

  .center {
    margin: 0 auto;
    text-align: center;
  }

  position: relative;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: #FFF;

  &.uncomplete {
    .gear-wrapper-1 {
      animation: gearEnter1 .5s ease 1s forwards;
    }

    .gear-wrapper-2 {
      animation: gearEnter2 .5s ease 1.5s forwards;
    }

    .gear-wrapper-3 {
      animation: gearEnter3 .5s ease 1.25s forwards;
    }

    .gear-wrapper-4 {
      animation: gearEnter4 .5s ease .75s forwards;
    }
  }

  &.complete {
    .gears {
      background-color: transparent;
      transition: background-color .25s ease .75s;
    }

    .gear-wrapper-1 {
      transform: rotate(10deg) translate3d(-5px, -5px, 0);
      animation: gearLeave1 .5s ease .5s forwards;
    }

    .gear-wrapper-2 {
      transform: rotate(25deg) translate3d(20px, -50px, 0);
      animation: gearLeave2 .5s ease .5s forwards;
    }

    .gear-wrapper-3 {
      transform: rotate(15deg) translate3d(-25px, -15px, 0);
      animation: gearLeave3 .5s ease .5s forwards;
    }

    .gear-wrapper-4 {
      transform: translate3d(0, 0, 0);
      animation: gearLeave4 .5s ease .5s forwards;
    }

    .loading-bar {
      animation: hideLoading .5s forwards;
    }

    .checkmark {
      &.checkmark-success {
        animation: fillCheckSuccess .4s cubic-bezier(.65, 0, .45, 1) 1s forwards, beatCheck .2s ease-out 1.5s forwards, echoSuccess 1.25s ease-out 1.5s forwards;

        .checkmark-circle {
          stroke: @brandMedium;
        }
      }

      &.checkmark-error {
        animation: fillCheckError .4s cubic-bezier(.65, 0, .45, 1) 1s forwards, beatCheck .2s ease-out 1.5s forwards, echoError 1.25s ease-out 1.5s forwards;

        .checkmark-circle {
          stroke: @errorRed;
        }
      }
    }

    .checkmark-circle {
      animation: strokeCheck .5s cubic-bezier(.65, 0, .45, 1) .75s forwards;
    }

    .checkmark-check {
      animation: strokeCheck .3s cubic-bezier(.65, 0, .45, 1) 1.25s forwards;
    }
  }


  h1 {
    display: block;
    color: @black;
    font-weight: 400;
  }

  h2 {
    display: block;
    color: @gray2;
    font-weight: 300;
  }

  .wrapper {
    position: relative;
    margin: 30px auto;
  }

  .gears {
    width: 200px;
    height: 200px;
    background-color: @gray2;
    border-radius: 50%;
    overflow: hidden;
    transform: scale(0);
    animation: scale .5s ease .5s forwards;
  }

  @keyframes scale {
    to {
      transform: scale(1);
    }
  }

  .gear-wrapper {
    position: absolute;

    &.gear-wrapper-1 {
      top: 0;
      left: 0;
      transform: rotate(10deg) translate3d(-40px, -60px, 0);
    }

    &.gear-wrapper-2 {
      top: 0;
      right: 0;
      transform: rotate(25deg) translate3d(70px, -130px, 0);
    }

    &.gear-wrapper-3 {
      bottom: 0;
      right: 0;
      transform: rotate(15deg) translate3d(30px, 20px, 0);
    }

    &.gear-wrapper-4 {
      bottom: 0;
      left: 0;
      transform: translate3d(-70px, 70px, 0);
    }
  }

  @keyframes gearEnter1 {
    to {
      transform: rotate(10deg) translate3d(-5px, -5px, 0);
    }
  }
  @keyframes gearEnter2 {
    to {
      transform: rotate(25deg) translate3d(20px, -50px, 0);
    }
  }
  @keyframes gearEnter3 {
    to {
      transform: rotate(15deg) translate3d(-25px, -15px, 0);
    }
  }
  @keyframes gearEnter4 {
    to {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes gearLeave1 {
    from {
      transform: rotate(10deg) translate3d(-5px, -5px, 0);
    }
    to {
      transform: rotate(10deg) translate3d(-40px, -60px, 0);
    }
  }
  @keyframes gearLeave2 {
    from {
      transform: rotate(25deg) translate3d(20px, -50px, 0);
    }
    to {
      transform: rotate(25deg) translate3d(70px, -130px, 0);
    }
  }
  @keyframes gearLeave3 {
    from {
      transform: rotate(15deg) translate3d(-25px, -15px, 0);
    }
    to {
      transform: rotate(15deg) translate3d(30px, 20px, 0);
    }
  }
  @keyframes gearLeave4 {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(-70px, 70px, 0);
    }
  }

  .gear {
    fill: @gray4;
    transform-origin: 50% 50%;

    &.gear-1 {
      width: 90px;
      height: 90px;
      animation: gearRotate 1s linear 2s infinite reverse;
    }

    &.gear-2 {
      width: 150px;
      height: 150px;
      animation: gearRotate 1.5s linear 2s infinite;
    }

    &.gear-3 {
      width: 60px;
      height: 60px;
      animation: gearRotate .75s linear 2s infinite reverse;
    }

    &.gear-4 {
      width: 120px;
      height: 110px;
      animation: gearRotate 1.25s linear 2s infinite;
    }
  }

  @keyframes gearRotate {
    to {
      transform: rotate(360deg);
    }
  }

  .loading-bar {
    position: relative;
    width: 200px;
    height: 10px;
    margin-top: 50px;
    background-color: @gray4;
    border-radius: 10px;
    overflow: hidden;

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: @gray2;
      transition: width .5s ease;
    }
  }

  @keyframes hideLoading {
    to {
      height: 0;
      margin: 0;
      opacity: 0;
    }
  }

  .checkmark {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;
    display: block;
    border-radius: 50%;
    stroke-width: 1px;
    stroke: #fff;
    stroke-miterlimit: 10;
  }

  .checkmark-circle {
    fill: none;
    stroke-dasharray: 200px;
    stroke-dashoffset: 200px;
    stroke-width: 1px;
    stroke-miterlimit: 10;
  }

  @keyframes fillCheckSuccess {
    to {
      box-shadow: inset 0 0 0 100px @brandMedium;
    }
  }
  @keyframes fillCheckError {
    to {
      box-shadow: inset 0 0 0 100px @errorRed;
    }
  }

  @keyframes beatCheck {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
  }

  @keyframes echoSuccess {
    from {
      box-shadow: inset 0 0 0 100px @brandMedium, 0 0 0 0 @brandMedium;
    }
    to {
      box-shadow: inset 0 0 0 100px @brandMedium, 0 0 0 50px transparent;
    }
  }
  @keyframes echoError {
    from {
      box-shadow: inset 0 0 0 100px @errorRed, 0 0 0 0 @errorRed;
    }
    to {
      box-shadow: inset 0 0 0 100px @errorRed, 0 0 0 50px transparent;
    }
  }

  .checkmark-check {
    stroke: #fff;
    stroke-dasharray: 100px;
    stroke-dashoffset: 100px;
    transform-origin: 50% 50%;
  }

  @keyframes strokeCheck {
    to {
      stroke-dashoffset: 0;
    }
  }
}