.participant-freeze-lock-area {
  div.content{
    div.help-text {
      color: @errorRed;
      .resizableFont(13px);
    }
  }
  button {
    width: 110px;
    text-align: center;
    &.selected {
      cursor: default;
      background: @brandMedium;
      border: solid 1px @brandMedium;
      > i {
        color: @white;
      }
      > h6 {
        color: @white;
      }
    }
  }
  div.timeline-area {
    position: relative;
    min-height: 100%;

    >.content {
      min-height: 100%;
      border: 0;
      background: transparent;

      ul.timeline-content {
        padding-top: 13px;

        >li.item {
          padding: 7px 0 7px 41px;
          width: 100%;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;

          >.item-wrap {
            position: relative;
            width: 100%;
            min-height: 60px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            background-color: @white;
            border: solid 1px @gray5;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;

            &:before {
              position: absolute;
              display: block;
              left: -28px;
              top: 50%;
              width: 12px;
              height: 12px;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              -webkit-border-radius: 6px;
              -moz-border-radius: 6px;
              border-radius: 6px;
              -webkit-transform: translateY(-50%);
              -moz-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              -o-transform: translateY(-50%);
              transform: translateY(-50%);
              background-color: @white;
              border: solid 2px @brandMedium;
              content: ' ';
            }
          }

          h3 {
            .fontLight();
            display: block;
            width: 100%;
            padding: 14px 160px 8px 20px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            color: @gray1;
          }
          h4 {
            .fontLight();
            position: absolute;
            display: block;
            margin: 12px 14px 8px 0;
            width: 150px;
            color: @gray3;
            text-align: right;
            right: 0;
          }
          @media (max-width: 767px) {
            h4 {
              position: relative;
              text-align: left;
              padding-left: 20px;
              height: 20px;
              line-height: normal;
            }
            h3 {
              padding: 0 8px 8px 20px;
            }
          }
          span {
            .fontLight();
            display: block;
            padding: 0 14px 18px 20px;
            .resizableFont(14px);
            letter-spacing: -0.4px;
            color: @gray2;
            word-break: break-word;
          }
          &.large {
            h3 {
              height: 52px;
              padding-bottom: 0;
            }
            h4 {
              margin-bottom: 0
            }
          }
        }
      }

      &:before {
        position: absolute;
        display: block;
        width: 2px;
        height: 100%;
        top: 0;
        left: 19px;
        content: ' ';
        background: @gray5;
      }
    }
  }
}
