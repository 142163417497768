@import "../../common/font";
@import "../../common/color";

div.patient-add-area {
  .patient-add-wrap {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    table {
      tr {
        background: @gray6 !important;
        td {
          border: none !important;
          .accordion-item {
            > label {
              height: 55px;
              > div {
                margin-top: 10px;
                margin-bottom: 10px;
                display: block;
                p.sub {
                  display: block;
                  font-size: 13px;
                  color: @gray3;
                }
                p.main {
                  display: block;
                }
              }
            }
          }
          div.accordion-content {
            line-height: 34px;
            li {
              width: 100%;
              margin: 18px 0;
              > * {
                display: inline-block;
              }
              >:nth-child(1) {
                vertical-align: top;
                p.sub {
                  line-height: 18px;
                  display: block;
                  font-size: 13px;
                  color: @gray3;
                }
                p.main {
                  line-height: 18px;
                  display: block;
                }
                width: ~"calc(100% - 70px)";
              }
              >:nth-child(2) {
                width: 70px;
                button {
                  margin-left: 8px;
                }
              }
            }
          }
        }
      }
    }

    @media (min-width: 768px) {
      &:nth-child(1) {
        padding-right: 10px;
      }
      &:nth-child(2) {
        padding-left: 10px;
      }
    }
  }

  .group-box {
    margin-bottom: 34px;

    > div.content {
      padding: 10px;

      ul.list {
        li {
          >div {
            min-height: 30px;
            line-height: 30px;
            padding: 10px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;

            &:nth-child(1) {
              .resizableFont(12px);
              text-align: right;
              color: @gray2;
            }
            &:nth-child(2) {
              input, textarea {
                width: 100%;
              }
              textarea {
                height: 120px;
              }
            }
            .radio-area {
              .radio-wrap {
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;

                >div {
                  background: @white;
                  border: 1px solid @gray5;
                  -webkit-border-radius: 20px;
                  -moz-border-radius: 20px;
                  border-radius: 20px;
                }

                &:nth-child(1) {
                  padding-right: 5px;
                }
                &:nth-child(2) {
                  padding-left: 5px;
                }
              }
            }
          }
        }
      }
    }

    &.part-info-area {
      >.part-info-wrap {
        padding: 20px;

        .combobox-area {
          width: 100%;

          button {
            width: 100%;
          }
        }
        .part-area {
          .part-wrap {
            padding: 6px 20px 14px 20px;
          }
        }
      }
    }
  }
}