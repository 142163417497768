#add-symptom-modal {
  .content-container {
    width: 100%;

    .header-area {
      position: absolute;
      top: 0;
      width: 100%;
      height: 60px;
      padding: 0 58px 0 20px;
      line-height: 60px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border-bottom: 1px solid @gray5;

      >.info-area {
        h3 {
          text-align: left;
          letter-spacing: 0;
          color: @gray1;

          span {
            .fontLight();
            .resizableFont(16px);
            letter-spacing: 0;
            color: @gray3;
          }
        }
      }
      >.close-area {
        position: absolute;
        right: 20px;
        top: 22px;
        cursor: pointer;

        i {
          display: block;
        }
      }
    }
    .body-area {
      width: 100%;
      height: 60vh;
      margin-top: 60px;
      padding-top: 8px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      overflow-y: scroll;

      > * {
        width: 100%;
      }
      .input-group {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 10px;

        label {
          color: @gray3;
        }
      }
      .input-box {
        padding: 0 10px;
        width: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        input {
          width: 100%;
        }
      }
      .filter-area {
        padding: 0 12px;

        > * {
          .fontRegular();
          float: left;
          padding: 0 4px;
          height: 40px;
          line-height: 40px;
          .resizableFont(14px);
          color: @gray1;
        }
      }
      ul.symptom-list {
        li {
          div.pure-g {
            display: inline-block;
            width: ~"calc(100% - 100px)";
            > * {
              display: inline-block;
            }
          }
          .button-area {
            display: inline-block;
            width: 78px;
            vertical-align: bottom;
            margin-bottom: 18px;
            button {
              width: 34px;
              line-height: 34px;
            }
          }
        }
      }
    }
    .footer-area {
      border-top: 1px solid @gray5;
      padding: 14px 10px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      >div {
        padding: 0 4px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        button {
          width: 100%;
        }
      }
    }
  }
}