@import "../common/constant";
@import "../common/color";

div.tooltip-container {
  margin: 0;
  padding: 0 10px;
  min-height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: @white;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 6px 0 @shadow1;
  -moz-box-shadow: 0 1px 6px 0 @shadow1;
  box-shadow: 0 1px 6px 0 @shadow1;
  color: @gray3;
  -webkit-transition: all @animationFast;
  -moz-transition: all @animationFast;
  -ms-transition: all @animationFast;
  -o-transition: all @animationFast;
  transition: all @animationFast;
}