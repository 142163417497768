@import "../common/constant";
@import "../common/color";

button.combobox {
  position: relative;
  width: 110px;
  padding: 0 30px 6px 16px;
  -webkit-border-radius: 17px;
  -moz-border-radius: 17px;
  border-radius: 17px;

  &.large-combobox {
    width: 220px;
  }

  h6 {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  i {
    position: absolute;
    width: 10px;
    height: 5px;
    right: 12px;
    top: 13.5px;
  }
  &.disabled {
    pointer-events: none;
    background-color: @gray5;
    h6 {
      color:@gray3;
    }
  }
}

div.combobox-select {
  position: absolute;
  margin-top: 8px;
  background: @white;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  z-index: 9999;
  -webkit-box-shadow: 0 1px 6px 0 @shadow1;
  -moz-box-shadow: 0 1px 6px 0 @shadow1;
  box-shadow: 0 1px 6px 0 @shadow1;
  -webkit-transition: height @animationNormal;
  -moz-transition: height @animationNormal;
  -ms-transition: height @animationNormal;
  -o-transition: height @animationNormal;
  transition: height @animationNormal;
  overflow: hidden;

  >.combobox-select-wrap {
    height: 100%;
    overflow-y: auto;

    >div.search {
      position: relative;
      margin: 10px 8px 6px 8px;

      >input {
        width: 100%;
        padding-right: 36px;
      }
      >input:hover + i {
        opacity: 1;
      }
      >input + i {
        position: absolute;
        right: 12px;
        top: 9px;
        opacity: 0.2;
      }
    }

    >div.item > div:hover {
      background-color: mix(@brandRegular, white, 10%);
    }
  }
}
body.mobile {
  div.combobox-select > .combobox-select-wrap > div.search{
    display: none;
  }
}

input.hidden-focus-input {
  position: fixed;
  top: -100px;
  left: -100px;
  width: 10px;
  height: 10px;
  opacity: 0;
}