@import "../common/font";
@import "../common/color";

.dashboard-area {
  .group-box {
    margin-bottom: 40px;
  }
  >div {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    &:nth-child(1) {
      padding-right: 10px;
    }
    &:nth-child(2) {
      padding-left: 10px;
    }
  }
}