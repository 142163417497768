@import "../common/constant";
@import "../common/color";

div.accordion {
  margin: 10px auto;
  .accordion-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .accordion-item {
    margin-bottom: 8px;
    border: 1px solid @gray5;
    border-radius: 5px;
    overflow: hidden;
  }
  .accordion-toggle {
    display: none;
    &:checked {
      &+.accordion-title {
        background: @white;
        color: @gray2;
        background: #fff;
        border-bottom: 1px dashed @gray5;
        &::after {
          transform: rotate(90deg);
        }
      }
      &~.accordion-content {
        height: auto;
        overflow: visible;
        padding-top: 10px;
        padding-bottom: 20px;
      }
    }
  }
  .accordion-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    padding: 0 20px;
    color: @gray1;
    background: @white;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.3s;
    &:hover {
      background: @gray6;
    }
    &::after {
      display: block;
      content: '\00276F';
      line-height: 30px;
      transition: 0.2s ease-in-out;
    }
  }
  .accordion-content {
    height: 0;
    overflow: hidden;
    padding: 0 30px;
    color: @gray2;
    background: @white;
    transition: 0.2s ease-in-out;
  }
}

