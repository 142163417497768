div.datepicker-container {
  .datepicker-addon-area {
    text-align: center;
    button {
      width: 90px;
      background: @brandMedium;
      border-radius: 5px;
      padding: 1px 5px;
      color: white;
      text-align: center;
    }
  }
}