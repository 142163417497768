@import "../common/constant";
@import "../common/color";

@keyframes shake {
  0%, 100% {
    transform: translate(0, 0);
  }
  10%, 90% {
    transform: translate(-1px, 0);
  }
  20%, 80% {
    transform: translate(2px, 0);
  }
  30%, 50%, 70% {
    transform: translate(-4px, 0);
  }
  40%, 60% {
    transform: translate(4px, 0);
  }
}
@-moz-keyframes shake {
  0%, 100% {
    -moz-transform: translate(0, 0);
  }
  10%, 90% {
    -moz-transform: translate(-1px, 0);
  }
  20%, 80% {
    -moz-transform: translate(2px, 0);
  }
  30%, 50%, 70% {
    -moz-transform: translate(-4px, 0);
  }
  40%, 60% {
    -moz-transform: translate(4px, 0);
  }
}
@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translate(0, 0);
  }
  10%, 90% {
    -webkit-transform: translate(-1px, 0);
  }
  20%, 80% {
    -webkit-transform: translate(2px, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate(-4px, 0);
  }
  40%, 60% {
    -webkit-transform: translate(4px, 0);
  }
}
@-o-keyframes shake {
  0%, 100% {
    -o-transform: translate(0, 0);
  }
  10%, 90% {
    -o-transform: translate(-1px, 0);
  }
  20%, 80% {
    -o-transform: translate(2px, 0);
  }
  30%, 50%, 70% {
    -o-transform: translate(-4px, 0);
  }
  40%, 60% {
    -o-transform: translate(4px, 0);
  }
}
@-ms-keyframes shake {
  0%, 100% {
    -ms-transform: translate(0, 0);
  }
  10%, 90% {
    -ms-transform: translate(-1px, 0);
  }
  20%, 80% {
    -ms-transform: translate(2px, 0);
  }
  30%, 50%, 70% {
    -ms-transform: translate(-4px, 0);
  }
  40%, 60% {
    -ms-transform: translate(4px, 0);
  }
}

input[type="text"]::-webkit-input-placeholder {
  color: @gray4;
}
input[type="text"]:-moz-placeholder {
  /* FF 4-18 */
  color: @gray4;
}
input[type="text"]::-moz-placeholder {
  /* FF 19+ */
  color: @gray4;
}
input[type="text"]:-ms-input-placeholder {
  /* IE 10+ */
  color: @gray4;
}
input[type="text"]::-ms-input-placeholder {
  /* Microsoft Edge */
  color: @gray4;
}
input[type="email"]::-webkit-input-placeholder {
  color: @gray4;
}
input[type="email"]:-moz-placeholder {
  /* FF 4-18 */
  color: @gray4;
}
input[type="email"]::-moz-placeholder {
  /* FF 19+ */
  color: @gray4;
}
input[type="email"]:-ms-input-placeholder {
  /* IE 10+ */
  color: @gray4;
}
input[type="email"]::-ms-input-placeholder {
  /* Microsoft Edge */
  color: @gray4;
}
input[type="password"]::-webkit-input-placeholder {
  color: @gray4;
}
input[type="password"]:-moz-placeholder {
  /* FF 4-18 */
  color: @gray4;
}
input[type="password"]::-moz-placeholder {
  /* FF 19+ */
  color: @gray4;
}
input[type="password"]:-ms-input-placeholder {
  /* IE 10+ */
  color: @gray4;
}
input[type="password"]::-ms-input-placeholder {
  /* Microsoft Edge */
  color: @gray4;
}
input[type="date"]::-webkit-input-placeholder {
  color: @gray4;
}
input[type="date"]:-moz-placeholder {
  /* FF 4-18 */
  color: @gray4;
}
input[type="date"]::-moz-placeholder {
  /* FF 19+ */
  color: @gray4;
}
input[type="date"]:-ms-input-placeholder {
  /* IE 10+ */
  color: @gray4;
}
input[type="date"]::-ms-input-placeholder {
  /* Microsoft Edge */
  color: @gray4;
}
input[type="tel"]::-webkit-input-placeholder {
  color: @gray4;
}
input[type="tel"]:-moz-placeholder {
  /* FF 4-18 */
  color: @gray4;
}
input[type="tel"]::-moz-placeholder {
  /* FF 19+ */
  color: @gray4;
}
input[type="tel"]:-ms-input-placeholder {
  /* IE 10+ */
  color: @gray4;
}
input[type="tel"]::-ms-input-placeholder {
  /* Microsoft Edge */
  color: @gray4;
}
input[type="number"]::-webkit-input-placeholder {
  color: @gray4;
}
input[type="number"]:-moz-placeholder {
  /* FF 4-18 */
  color: @gray4;
}
input[type="number"]::-moz-placeholder {
  /* FF 19+ */
  color: @gray4;
}
input[type="number"]:-ms-input-placeholder {
  /* IE 10+ */
  color: @gray4;
}
input[type="number"]::-ms-input-placeholder {
  /* Microsoft Edge */
  color: @gray4;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="date"],
input[type="number"],
input[type="tel"] {
  margin: 0;
  padding: 0 12px;
  height: 34px;
  border-radius: 20px;
  background-color: @white;
  border: solid 1px @gray5;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all @animationFast;
  -moz-transition: all @animationFast;
  -ms-transition: all @animationFast;
  -o-transition: all @animationFast;
  transition: all @animationFast;
  .resizableFont(13px);
  letter-spacing: -0.4px;
  text-align: left;
  color: @gray2;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    border-color: @gray3;

    h6 {
      color: @white !important;
    }
  }
  &:disabled, &[readonly] {
    background-color: @gray5;
    color: @gray3;
  }
}

.input-group {
  >label {
    display: block;
    padding: 0 12px 6px 12px;
    .resizableFont(12px);
    letter-spacing: -0.4px;
    text-align: left;
    height: 20px;
    line-height: 20px;

    >span {
      .resizableFont(12px);
      letter-spacing: -0.4px;
      color: @gray3;
    }
  }
}
.input-box {
  position: relative;

  > i {
    position: absolute;
    display: block;
    left: 14px;
    top: 9px;
    width: 16px;
    height: 16px;
    z-index: 1;
  }
  > i + input[type="text"],
  > i + input[type="email"],
  > i + input[type="password"],
  > i + input[type="date"],
  > i + input[type="tel"] {
    width: 100%;
    padding-left: 38px;
  }
  >span.error {
    position: absolute;
    white-space: nowrap;
    display: none;
    margin-left: 12px;
    .resizableFont(10px);
    color: @errorRed;
    text-align: center;
  }

  > div.combobox-area +span.error {
    margin-top: -5px;
  }

  > div.combobox + span.error {
    margin-top: -5px;
  }

  &.error {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    -webkit-animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    -moz-animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    -o-animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    -ms-animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -o-animation-iteration-count: 1;
    -ms-animation-iteration-count: 1;
    perspective: 1000px;

    > input[type="radio"],
    > input[type="text"],
    > input[type="email"],
    > input[type="password"],
    > input[type="date"],
    > input[type="tel"],
    > input[type="number"],
    > button {
      border-color: @errorRed;
    }
    >span.error {
      display: block;
    }
  }
}