@import "../common/constant";
@import "../common/color";

div.search-bar {
  display: inline-block;
  position: relative;

  >.combobox-area {
    position: absolute;
    display: block;
    top: 0;
    z-index: 1;
  }
  >input {
    width: 100%;
    padding-left: 114px;
    padding-right: 36px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  >input:hover + .combobox-area > button.combobox {
    border-color: @gray3;
  }
  >input:hover + .combobox-area + i {
    opacity: 1;
  }
  >i {
    position: absolute;
    right: 12px;
    top: 9px;
    opacity: 0.2;
  }
}