@import "../common/constant";
@import "../common/color";

ul.breadcrumb {
  margin-top: 4px;
  
  >li {
    display: inline;
    margin-left: 15px;
    letter-spacing: 0.8px;

    &:before {
      position: absolute;
      width: 6px;
      height: 10px;
      margin-left: -12px;
      margin-top: 4.5px;
      background: url("@{imagePath}/icon-breadcrumb.png") no-repeat;
      content: ' ';
    }

    &:nth-child(1) {
      margin: 0;

      &:before {
        content: '';
        background: none !important;
      }
    }

    a {
      color: @gray3;

      &:hover {
        color: @gray2;
      }
    }
  }
}
/// 2x
body.double ul.breadcrumb > li:before {
  background-image: url("@{imagePath}/icon-breadcrumb@2x.png");
}
/// 3x
body.triple ul.breadcrumb > li:before {
  background-image: url("@{imagePath}/icon-breadcrumb@3x.png");
}
/// svg
body.svg ul.breadcrumb > li:before {
  background-image: url("@{imagePath}/icon-breadcrumb.svg");
}