@import "../common/color";
@import "../common/font";

div.pager {
  width: 100%;
  text-align: center;

  ul {
    display: inline-block;
    height: 40px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    background-color: @white;
    border: solid 1px @gray5;

    li {
      display: block;
      float: left;
      margin: 5px 6px;
      width: 28px;
      height: 28px;
      line-height: 26px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      text-align: center;
      cursor: pointer;

      a {
        .fontBold();
        display: block;
        width: 100%;
        height: 100%;
        .resizableFont(12px);
        letter-spacing: 0;
        color: @gray3;
      }

      &.left, &.right {
        margin: 5px 10px;
        line-height: 28px;
      }
      &.disable {
        opacity: 0.2;
        pointer-events: none;
      }
      &:hover {
        background-color: @gray5;
      }
      &.active {
        background-color: @brandMedium;
        a {
          color: @white;
        }
      }
    }
  }
}