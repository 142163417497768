#upsert-filter-modal {

  .content-container {
    width: 100%;

    .header-area {
      position: absolute;
      top: 0;
      width: 100%;
      height: 60px;
      padding: 0 58px 0 20px;
      line-height: 60px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border-bottom: 1px solid @gray5;

      >.info-area {
        h3 {
          text-align: left;
          letter-spacing: 0;
          color: @gray1;

          span {
            .fontLight();
            .resizableFont(16px);
            letter-spacing: 0;
            color: @gray3;
          }
        }
      }
      >.close-area {
        position: absolute;
        right: 20px;
        top: 22px;
        cursor: pointer;

        i {
          display: block;
        }
      }
    }
    .body-area {
      height: 60vh;
      margin-top: 60px;
      padding-top: 8px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      overflow-y: scroll;

      .input-group {
        padding-bottom: 10px;

        label {
          color: @gray3;
        }
      }
      .input-box {
        padding: 0 10px;
        width: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        input {
          width: 100%;
        }
      }
      ul.value-list {
        padding: 0 8px;

        &.string-type {
          input:not(.string-type) {
            display: none;
          }
          .combobox.combobox-base:not(.string-type) {
            display: none;
          }
        }
        &.number-type {
          input:not(.number-type) {
            display: none;
          }
          .combobox.combobox-base:not(.number-type) {
            display: none;
          }
        }
        &.date-type {
          input:not(.date-type) {
            display: none;
          }
          .combobox.combobox-base:not(.date-type) {
            display: none;
          }
        }
        &.gender-type {
          input:not(.gender-type) {
            display: none;
          }
          .combobox.combobox-base:not(.gender-type) {
            display: none;
          }
        }
        &.part-type {
          input:not(.part-type) {
            display: none;
          }
          .combobox.combobox-base:not(.part-type) {
            display: none;
          }
        }
        &.predefined-symptom-type {
          input:not(.predefined-symptom-type) {
            display: none;
          }
          .combobox.combobox-base:not(.predefined-symptom-type) {
            display: none;
          }
        }

        > li {
          > *:nth-child(1) {
            width: 100%;
          }
          > *:nth-child(2) {
            display: none;
            width: 72px;
          }
        }

        &.multiple > li {
          > * {
            float: left;
            margin: 0 2px;
          }
          .button-area {
            margin-left: 6px;

            button {
              width: 34px;
              line-height: 34px;
            }
          }
          > *:nth-child(1) {
            width: ~"calc(100% - 90px)";
          }
          > *:nth-child(2) {
            display: block;
            width: 72px;
          }
        }
      }
    }
    .footer-area {
      border-top: 1px solid @gray5;
      padding: 14px 10px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      >div {
        padding: 0 4px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        button {
          width: 100%;
        }
      }
    }
  }
}