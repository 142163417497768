@import "../common/font";
@import "../common/color";
.iziModal.base-modal {
  div.header-area {
    h1.title {
      display: block;
      width: 100%;
      line-height: 50px;
      padding: 0 20px;
      margin-bottom: 6px;
      text-align: center;
      color: @gray1;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }

    > .prev-area {
      position: absolute;
      top: 14px;
      left: 20px;
    }
    > .close-area {
      position: absolute;
      right: 20px;
      top: 22px;
      cursor: pointer;

      i {
        display: block;
      }
    }
  }
  div.body-area {
    p {
      &.desc {
        font-weight: 300;
        display: block;
        width: 100%;
        min-height: 32px;
        padding: 0 20px;
        margin-bottom: 6px;
        text-align: center;
        color: @gray1;
        .resizableFont(14px);
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }

      &.title {
        display: block;
        width: 100%;
        height: 50px;
        line-height: 50px;
        padding: 0 20px;
        margin-bottom: 6px;
        text-align: center;
        color: @gray1;
        .resizableFont(16px);
        font-weight: 600;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        margin-top: 30px;
      }
    }
  }
  .content-container {
    h3.title {
      display: block;
      width: 100%;
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
      margin-bottom: 6px;
      text-align: center;
      color: @gray1;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
    h3.desc {
      .fontLight();
      display: block;
      width: 100%;
      min-height: 32px;
      padding: 0 20px;
      margin-bottom: 6px;
      text-align: center;
      color: @gray1;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      word-break: keep-all;

      b {
        .fontRegular();
        color: @gray1;
        .resizableFont(16px);
        letter-spacing: 0.5px;
      }
    }
    >div {
      padding: 6px 10px 14px 10px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      >div {
        padding: 0 4px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        button {
          width: 100%;
        }
      }
    }
  }

  &.base-modal-input {
    .input-group {
      display: block;
      padding: 0 14px 14px 14px;
      margin-bottom: 6px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      input {
        width: 100%;
      }
    }
  }
}

.iziModal, .iziModal-overlay {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}