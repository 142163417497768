@import "../common/font";
@import "../common/color";

div.folder-container {
  margin-bottom: 48px;
}
.folder-area {
  div.table-container {
    padding: 0 10px;
    margin-bottom: 4px;
  }
  ul.folder-wrap {
    margin: 0 -6px;

    li.folder {
      float: left;
      width: 25%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0 6px 12px 6px;

      .folder-content {
        position: relative;
        height: 60px;
        line-height: 60px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        background-color: @white;
        border: solid 1px @gray5;

        &:hover {
          h4 {
            padding-right: 42px;
          }
          i.info {
            right: 16px;
            opacity: 1;
          }
        }

        &.small {
          height: 50px;
          line-height: 50px;
        }

        i {
          position: absolute;
          display: block;
          vertical-align: middle;
        }
        i.icon {
          width: 35px;
          height: 30px;
          background-size: contain;
          left: 15px;
          top: 14px;
          color: @gray1;
          &.ps-foldercell-share {
            width: 38px;
          }
        }
        i.info {
          right: 0;
          top: 21px;
          opacity: 0;
          cursor: pointer;
          -webkit-transition: all @animationFast;
          -moz-transition: all @animationFast;
          -ms-transition: all @animationFast;
          -o-transition: all @animationFast;
          transition: all @animationFast;
        }
        p.smart-folder {
          position: absolute;
          font-size: 12px;
          left: 27px;
          top: 3px;
          color: @gray1;
          .fontBold();
          &:after{
            content: 'S';
          }
          &:hover{
            color: @gray1;
          }
        }
        h4 {
          .fontLight();
          display: block;
          padding: 0 16px 0 55px;
          height: 60px;
          width: 100%;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          border-radius: 4px;
          text-align: left;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          color: @gray1;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          -webkit-transition: all @animationFast;
          -moz-transition: all @animationFast;
          -ms-transition: all @animationFast;
          -o-transition: all @animationFast;
          transition: all @animationFast;
        }
      }
      &.shared {

      }
    }
  }
}
@media (min-width: 1440px) {
  .folder-area ul.folder-wrap li.folder {
    width: 20%;
  }
}
@media (max-width: 767px) {
  .folder-area ul.folder-wrap li.folder {
      width: 50%;
  }
}
@media (max-width: 575px) {
  .folder-area ul.folder-wrap li.folder {
      width: 100%;
  }
}