@import "../common/constant";
@import "../common/font";
@import "../common/color";

.rs-container {
  margin: 100px auto 0;
  .rs-range-color {
    background-color: @brandMedium;
  }
  .rs-path-color {
    background-color: @brandRegular;
  }
  .rs-handle {
    background-color: @brandRegular;
    padding: 7px;
    border: 2px solid @brandRegular;
    margin: -12px 0px 0px -7px;
    &:after {
      background-color: @brandRegular;
      display: block;
      content: " ";
      width: 100%;
      position: absolute;
      top: -3px;
      right: 4px;
      border: 4px solid @gray5;
      z-index: -1;
    }
    &:before {
      display: block;
      content: " ";
      position: absolute;
      height: 12px;
      width: 12px;
      background: black;
      right: -4px;
      bottom: -7px;
      border-radius: 100%;
    }
  }
  .rs-handle.rs-focus {
    border-color: @brandMedium;
  }
  .rs-border {
    border-color: transparent;
  }
  .rs-tooltip {
    top: -80px;
    width: 140px;
    height: 48px;
  }
  .rs-tooltip-text {
    font-weight: bold;
    .resizableFont(30px);
  }
  .rs-numbers.rs-bar {
    .resizableFont(20px);
    font-weight: bold;
  }
  .rs-numbers {
    .rs-seperator {
      border: none;
      height: auto;
      position: absolute;
      top: -10px;
      left: -30px;
      width: auto !important;
    }
  }
  .rs-desc {
    display: inline-block !important;
    .resizableFont(14px) !important;
    font-weight: bold;
    &.desc-left {
      float: left;
      margin-left: -35px !important;
    }
    &.desc-right {
      float: right;
      margin-right: -35px !important;
    }
  }
}