@import "../common/font";

.notice-detail-area {
  margin-bottom: 40px;

  div.content {
    padding: 20px;

    pre {
      color: @gray2;
    }
  }
}