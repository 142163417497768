@import "../common/font";
@import "../common/color";

#reservation-manage-modal, #ecrf-reservation-manage-modal {
  width: 480px;
  min-width: 480px;

  .content-container {
    position: relative;
    width: 100%;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: @white;

    .header-area {
      position: absolute;
      width: 100%;
      height: 60px;
      padding: 0 58px 0 20px;
      line-height: 60px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border-bottom: 1px solid @gray5;

      >.info-area {
        h3 {
          text-align: left;
          .resizableFont(16px);
          letter-spacing: 0;
          color: @gray1;
          padding: 0;
          margin: 0;
          height: 60px;
          line-height: 60px;
        }
      }
      >.close-area {
        position: absolute;
        right: 20px;
        top: 22px;
        cursor: pointer;

        i {
          display: block;
        }
      }
    }
    .body-area {
      width: 100%;
      height: 336px;
      padding: 60px 20px 0 20px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      >.table-container {
        tr {
          width: 480-20;
          margin-left: 10px;
          margin-right: 10px;
          padding-left: 10px;
          padding-right: 10px;
          >td {
            padding: 10px;
          }
        }
      }
    }
    .footer-area {
      border-top: 1px solid @gray5;
      padding-top: 14px;

      button {
        margin: 0;
      }
    }
  }
}