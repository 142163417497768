@import "../common/constant";
@import "../common/font";
@import "../common/color";

div.slider {
  position: relative;
  padding: 10px;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;

  > div.handler {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border: 9px solid #1d99f1;
    background: #ffffff;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    cursor: move;
    z-index: 2;
  }
  > div.percent-line {
    position: relative;
    width: 0;
    height: 20px;
    margin-top: -20px;
    background: #8ac5ef;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    z-index: 1;
  }
  > div.max-line {
    display: block;
    width: 100%;
    height: 20px;
    background: #eeeeee;
    position: relative;
    border: 1px solid #dfe2e3;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  > div.ticks {
    position: relative;
    margin: 16px 10px 10px 10px;
    height: 20px;

    > .tick {
      position: absolute;
      margin-left: -20px;
      width: 40px;
      height: 20px;
      text-align: center;
      font-weight: bold;
    }
  }
  > div.desc {
    position: relative;
    margin-top: 20px;

    > .min {
      position: absolute;
      top: 0;
      left: 0;
    }
    > .max {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
