@import "../../../common/constant";
@import "../../../common/color";
@import "../../../common/font";

#join {
  width: 100%;
  height: 100%;
  background: @black;

  @media (max-width: 1023px) {
    background: transparent;

    >.join-container-wrap {
      >.form-area {
        padding: 54px 0;
        width: 320px !important;
        height: auto !important;

        > .form-wrap {
          padding: 0 20px !important;
          background: transparent !important;
        }
      }
    }
  }

  >.join-container-wrap {
    position: relative;
    text-align: center;

    @media (min-width: 1024px) {
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    >.header-area {
      background: @black;
      height: 44px;

      i {
        margin-top: 12px;
      }
    }

    >.form-area {
      margin: 0 auto;
      width: 640px;
      height: 480px;

      .form-wrap {
        padding: 54px 20px;
        background: @white;
        text-align: left;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        .header-area {
          padding-bottom: 42px;

          .image {
            text-align: center;
          }
          .information {
            padding-top: 24px;
            text-align: center;
            height: 40px;
            line-height: 40px;

            h1 {
              .fontBold();
              .resizableFont(24px);
              letter-spacing: 1.3px;
              text-align: center;
              color: @black;
            }

            &:after {
              position: relative;
              display: block;
              margin: 0 auto;
              width: 34px;
              height: 2px;
              background-color: @black;
              content: ' ';
            }
          }
        }
        .content-area {
          .input-group {
            padding-bottom: 24px;

            .combobox {
              width: 100%;
            }
          }
          .button-area {
            padding-top: 4px;

            .button-wrap button {
              width: 100%;
            }
          }
          .pure-g > div {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;

            &:nth-child(1) {
              padding-right: 4px;
            }
            &:nth-child(2) {
              padding-left: 4px;
            }
          }
        }
      }
    }
  }
}