#add-questionnaire-modal, #ecrf-add-questionnaire-modal {
  .content-container {
    h3 {
      border-bottom: 1px solid @gray5;
    }
    div.list {
      padding: 0;
      height: 330px;
      overflow-y: scroll;

      .section {
        position: relative;
        padding: 0;
        width: 100%;
        -webkit-transition: all @animationFast;
        -moz-transition: all @animationFast;
        -ms-transition: all @animationFast;
        -o-transition: all @animationFast;
        transition: all @animationFast;
        &:hover {
          background-color: fade(@brandRegular, 10%);
        }
        i {
          position: absolute;
          top: 12px;
          right: 10px;
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          transform: rotate(180deg);
          opacity: 0.4;
        }
        h2 {
          .fontRegular();
          display: block;
          .resizableFont(12px);
          padding: 0 32px 0 10px;
          height: 30px;
          line-height: 30px;
          color: @gray3;
          cursor: pointer;
          overflow:hidden;
          text-overflow:ellipsis;
          white-space:nowrap;
        }
        ul {
          li {
            .fontRegular();
            padding: 0 20px;
            height: 30px;
            line-height: 30px;
            .resizableFont(12px);
            color: @gray1;
            background-color: @white;
            cursor: pointer;

            &:hover {
              background-color: fade(@brandRegular, 10%);
            }
          }
        }
        &.hide {
          height: 30px;
          overflow: hidden;

          i {
            -webkit-transform: rotate(0);
            -moz-transform: rotate(0);
            -ms-transform: rotate(0);
            -o-transform: rotate(0);
            transform: rotate(0);
          }
        }
      }
    }
  }
}
