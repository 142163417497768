@import "../common/constant";
@import "../common/color";

.sidebar-container {
  .brand-area {
    margin: 0 auto;
    width: 100%;
    height: 174px;
    background-color: @black;

    a {
      width: 100%;
    }

    .brand-wrap {
      padding-top: 47px;
      padding-bottom: 30px;
      text-align: center;

      .text {
        margin-top: 20px;
      }
    }
  }
  .menu-area {
    background-color: @black;
    .case-running-title {
      display: block;
      width: 180px;
      margin: 20px auto;
      color: @brandMedium;
      font-weight: bold;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .link-area {
      a {
        cursor: pointer;
        display: block;
        background: rgba(255, 255, 255, 0.25);
        height: 50px;
        position: relative;
        border-radius: 1000px;
        margin: 30px 10px 10px;
        i {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 18px;
          font-size: 20px;
          color: @white;
        }
        img.home {
          position: absolute;
          top: 50%;
          transform: translate(-50%,-50%);
          left: 50%;
          width: 110px;
          margin-left: 12px;
        }
      }
    }
  }
  .footer-area {
    margin: auto 0 30px 0;
    width: 100%;
    z-index: -1;

    .footer-wrap {
      text-align: center;
      padding: 0 10px;
      >.swiper-big-ad-container {
        width: 190px;
        height: 288px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      >.swiper-small-ad-container {
        width: 190px;
        height: 54px;
        margin-bottom: 10px;
      }
      >i {
        padding-bottom: 11px;
      }
      >h5 > a {
        display: block;
        .resizableFont(11px);
        color: @gray2;

        &:hover {
          color: @gray3;
        }
      }
      >h6 > a {
        display: block;
        .resizableFont(10px);
        color: @gray2;
        margin-top: -1px;

        &:hover {
          color: @gray3;
        }
      }
    }
  }
  @media (max-height: 1024px) {
    .brand-area {
      .brand-wrap {
        padding-top: 27px;
        padding-bottom: 17px;
      }
    }
    .footer-area {
      margin-bottom: 10px;
    }
  }
}
body.ecrf {
  .sidebar-container {
    background-color: @ecrfSub;
    .brand-area {
      background-color: @ecrfSub;
    }
    .menu-area {
      background-color: @ecrfSub;
    }
  }
}
@media screen and (max-width: 1023px) {
  .sidebar-container {
    -webkit-transition: height @animationNormal !important;
    -moz-transition: height @animationNormal !important;
    -ms-transition: height @animationNormal !important;
    -o-transition: height @animationNormal !important;
    transition: height @animationNormal !important;

    .brand-area {
      width: 100%;
      height: 44px;

      .brand-wrap {
        display: block;
        margin: 0 auto;
        padding: 0;
        width: 140px;
        height: 44px;
        line-height: 44px;

        .icon {
          display: inline;
          position: relative;
          top: 3px;

          i {
            width: 19px;
            height: 20px;
            background-size: cover;
          }
        }
        .text {
          display: inline;
          position: relative;
          top: 0;
          margin: 0 0 0 8px;

          i {
            width: 100px;
            height: 12.89px;
            background-size: cover;
          }
        }
      }
      .hamburger-area {
        position: absolute;
        top: 14px;
        left: 14px;
      }
      .notification-area {
        position: absolute;
        top: 10px;
        right: 14px;
      }
    }
    .menu-area {
      border-top: 1px solid @gray1;
    }
    .footer-area {
      display: none;
    }
    &.open {
      height: 100vh;
    }
  }
}
