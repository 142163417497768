div.participant-result-area {
  .table-container table {
    width: 100%;
    thead {
      font-weight: 500;
    }
    tr {
      height: 45px;
    }
  }
  div.questionnaire-container {
    .questionnaire-body-area {
      overflow-x: auto;
      display: block;
      width: 100%;

      table {
        width: 100%;
        table-layout: fixed;
        border-collapse: separate;
        border-spacing: 0 4px;
        color: @gray2;

        td, th {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          text-align: center;
          .resizableFont(14px);
          letter-spacing: -0.4px;
        }

        thead {
          th {
            position: relative;
            height: 45px;

            &:nth-last-child(1) {
              padding: 0;
            }
          }
        }
        tbody {
          tr {
            height: 45px;
            margin-bottom: 4px;
            background-color: @white;
            td {
              .fontLight();
              border-top: solid 1px @gray5;
              border-bottom: solid 1px @gray5;
              text-align: center;
              cursor: pointer;

              &.empty {
                text-align: center;
                pointer-events: none;
              }
              ul.presetList {
                li {
                  .fontLight();
                }
              }
              button {
                margin: 0;
              }
            }
            td:nth-child(1) {
              border-left: solid 1px @gray5;
              -webkit-border-top-left-radius: 5px;
              -moz-border-top-left-radius: 5px;
              border-top-left-radius: 5px;
              -webkit-border-bottom-left-radius: 5px;
              -moz-border-bottom-left-radius: 5px;
              border-bottom-left-radius: 5px;
            }
            td:nth-last-child(1) {
              border-right: solid 1px @gray5;
              -webkit-border-top-right-radius: 5px;
              -moz-border-top-right-radius: 5px;
              border-top-right-radius: 5px;
              -webkit-border-bottom-right-radius: 5px;
              -moz-border-bottom-right-radius: 5px;
              border-bottom-right-radius: 5px;
            }
          }
        }
      }
    }
  }
}