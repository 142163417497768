@import "../common/color";

ul.menu {
  width: 100%;

  >li {
    position: relative;
    //height: 52px;
    //line-height: 52px;
    height: 46px;
    line-height: 46px;
    vertical-align: middle;

    > a {
      width: 100%;
      display: block;
    }

    i {
      //margin-left: 30px;
      margin-left: 24px;
    }
    span.badge {
      .fontBold();
      position: absolute;
      display: block;
      .resizableFont(12px);
      letter-spacing: 0;
      width: 36px;
      top: 0;
      right: 10px;
      text-align: center;
      color: @brandLight;
    }
    h3.content {
      height: 100%;
      padding-left: 8px;
      color: @gray4;
    }

    &.active {
      h3.content {
        color: @brandLight;

        &:before {
          position: absolute;
          display: block;
          top: 50%;
          left: -7px;
          width: 14px;
          height: 14px;
          -webkit-border-radius: 7px;
          -moz-border-radius: 7px;
          border-radius: 7px;
          content: ' ';
          background: @brandLight;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%);
        }
      }
    }
  }

  @media (max-height: 1024px) {
    >li {
      height: 38px;
      line-height: 38px;
    }
  }
}