@import "../common/constant";
@import "../common/color";

div.radiobox {
  position: relative;
  display: block;
  height: 40px;

  >input[type="radio"] {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
  }
  >input[type="radio"] + div.state {
    display: table;
    height: 40px;
    padding: 0 10px 0 36px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    >label {
      display: table-cell;
      vertical-align: middle;
      line-height: normal;
      color: @gray3;
      overflow: hidden;
      &:before {
        position: absolute;
        display: block;
        top: 50%;
        left: 12px;
        width: 16px;
        height: 16px;
        background-color: @white;
        border: solid 1px @brandMedium;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        content: ' ';
      }
      &:after {
        position: absolute;
        top: 50%;
        left: 15px;
        width: 10px;
        height: 10px;
        background-color: @brandMedium;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        content: ' ';
        -webkit-transition: all @animationFast;
        -moz-transition: all @animationFast;
        -ms-transition: all @animationFast;
        -o-transition: all @animationFast;
        transition: all @animationFast;
        -webkit-transform: translateY(-50%) scale(0, 0);
        -moz-transform: translateY(-50%) scale(0, 0);
        -ms-transform: translateY(-50%) scale(0, 0);
        -o-transform: translateY(-50%) scale(0, 0);
        transform: translateY(-50%) scale(0, 0);
      }
    }
  }
  >input[type="radio"]:disabled + div.state, >input[type="radio"][readonly] + div.state {
    >label {
      &:before {
        background-color: @gray5;
        border: solid 1px @gray3;
      }
      &:after {
        background-color: @gray3;
      }
    }
  }

    >input[type="radio"]:checked + div.state > label {
    &:after {
      -webkit-transform: translateY(-50%) scale(1, 1);
      -moz-transform: translateY(-50%) scale(1, 1);
      -ms-transform: translateY(-50%) scale(1, 1);
      -o-transform: translateY(-50%) scale(1, 1);
      transform: translateY(-50%) scale(1, 1);
    }
  }
}