@import "../common/constant";
@import "../common/color";
@import "../common/font";

section.tab-container {
  margin: 0;
  padding: 0;

  >div.tab-area {
    margin: 32px -12px 0 0px;

    >div.tab-wrap {
      overflow-x: auto;
      &::-webkit-scrollbar-track
      {
        background-color: @gray6;
      }
      &::-webkit-scrollbar
      {
        background-color: @gray5;
        height: 8px;
      }
      &::-webkit-scrollbar-thumb
      {
        background-color: @gray5;
        border-color: 1px solid @gray5;
        border-radius: 100px;
      }

      > ul {
        white-space: nowrap;
        > li.tab {
          float: left;
          display: block;
          margin: 0 0 2px 0;
          padding: 0;
          width: 140px;
          height: 46px;
          line-height: 46px;
          cursor: pointer;

          a {
            margin: 0;
            padding: 0;
            display: block;
            width: 100%;
            text-align: center;
            .resizableFont(16px);
            color: @gray3;
          }
          &.active {
            a {
              color: @gray1;
            }
            &:after {
              position: relative;
              display: block;
              margin: 0 auto;
              top: -3px;
              width: 100px;
              height: 3px;
              background: @brandMedium;
              content: ' ';
            }
          }
        }
      }
    }

    &:after {
      position: relative;
      display: block;
      margin: 0 -48px;
      top: -1px;
      height: 1px;
      content: ' ';
      background: @gray4;
    }
  }
}
@media (max-width: 1023px) {
  section.tab-container > div.tab-area:after {
    margin: 0 -8px;
  }
}

