@selected: #778899;
@yes: #43a047;
@no: @errorRed;
@yet: #f9a825;
.calendar-container {
  * {
    box-sizing: border-box;
  }
  width: 100%;
  margin: auto;
  background-color: #ebebeb;
  .clndr-controls {
    padding: 13px;
    background-color: @brandMedium;
    color: white;
    text-align: center;
    .current-month {
      display: inline-block;
      .resizableFont(16px);
      font-weight: 600;
      cursor: pointer;
      padding: 0 10px;
      border: 2px solid white;
      border-radius: 30px;
      &:hover {
        background-color: @brandMain;
      }
    }
    .clndr-previous-button {
      float: left;
      text-align: left;
      width: 20px;
      cursor: pointer;
      padding: 2px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      &:hover {
        opacity: 0.5;
      }
    }
    .clndr-next-button {
      float: right;
      text-align: right;
      width: 20px;
      cursor: pointer;
      padding: 2px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  .clndr-grid {
    float: left;
    width: 100%;
    .days-of-the-week {
      width: 100%;
      .header-day {
        background: @brandLight;
        font-weight: 600;
        .resizableFont(15px);
        float: left;
        width: 14.2857%;
        padding: 14px;
        text-align: center;
        color: white;
      }
    }
    .days {
      width: 100%;
      > div {
        float: left;
        width: 14.2857%;
        height: 100px;
        padding: 15px 0;
        text-align: center;
        color: @gray1;
        border: 1px solid white;
        background-position: center;
        .day-number {
          .resizableFont(15px);
          font-weight: 500;
        }
        div.status {
          margin-top: 16px;
          p {
            padding: 5px 10px;
            border-radius: 30px;
            color: white;
            .resizableFont(15px);
            font-weight: 600;
            &.yes {
              border: @yes;
              background: @yes;
            }
            &.no {
              border: @no;
              background: @no;
            }
            &.yet {
              border: @yet;
              background: @yet;
            }
            @media screen and (max-width: 780px) {
              span {
                display: none;
              }
            }
          }
        }
        &.day {
          background-color: @gray5;
          &.event {
            cursor: pointer;
            .day-number {
              border-bottom: 2px solid @brandMain;
            }
          }
          &.today {
            background-color: @brandRegular;
            color: white;
            background-image: none;
          }
          &:hover {
            background-color: @selected;
            color: white;
          }
        }
        &.empty {
          background-color: @gray6;
          &.event {
            .day-number {
              border-bottom: 2px solid @brandMain;
            }
          }
        }
      }
    }
  }
  .noselect {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
}
