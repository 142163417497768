@import "../common/constant";
@import "../common/color";

.ui-autocomplete {
  position:absolute;
  cursor:default;
  margin-top: 8px;
  background: @white;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  z-index: 9999;
  -webkit-box-shadow: 0 1px 6px 0 @shadow1;
  -moz-box-shadow: 0 1px 6px 0 @shadow1;
  box-shadow: 0 1px 6px 0 @shadow1;
  -webkit-transition: height @animationNormal;
  -moz-transition: height @animationNormal;
  -ms-transition: height @animationNormal;
  -o-transition: height @animationNormal;
  transition: height @animationNormal;
  overflow: hidden;
  > li.ui-menu-item {
    border-bottom: 1px solid @gray4;
    &:last-child {
      border-bottom: none;
    }
    > div {
      div.sub {
        .fontLight();
        .resizableFont(12px);
      }
    }
  }
}