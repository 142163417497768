@import "../../common/constant";
@import "../../common/font";
@import "../../common/color";

#questionnaire-page-area {
  margin: 0;
  padding: 0;
  width: 100%;
  /// height를 viewport height(vh)로 지정한다.
  height: 100vh;
  /// overflow 지정을 통해 화면을 벗어나는 영역을 노출하지 않는다.
  overflow: hidden;
  > div.questionnaire-container {
    > .header-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 72px;
      z-index: 100;
      background-color: @black;
      color: @white;
      text-align: center;

      .title {
        margin-top: 18px;
        display: inline-block;
        font-weight: 700;
        .resizableFont(24px);
        letter-spacing: 1px;
      }
      @media (max-width: 767px) {
        .title {
          margin-top: 18px;
          display: inline-block;
          font-weight: 700;
          .resizableFont(17px);
          letter-spacing: 1px;
        }
      }
      #prev {
        margin-top: 18px;
        height: 36px;
        float: left;
        margin-left: 10px;
        color: #000000;
      }
      #next {
        margin-top: 18px;
        height: 36px;
        float: right;
        margin-right: 10px;
        color: #000000;
      }
    }

    .footer-container {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 72px;
      z-index: 100;

      img {
        display: block;
        margin: 0 auto;
        height: 72px;
      }
    }

    > #questionnaire-page-wrapper {
      padding-top: 72px;
      height: 100vh;
      padding-bottom: 72px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      background-color: #e9e9e9;

      > form.questionnaire {
        height: 100%;
        float: left;
        background-color: @gray6;
        > section.questionnaire-page {
          width: 100vw;
          max-height: 100%;
          float: left;
          color: @black;
          background-color: @gray6;
          /// 스크롤을 자동으로 노출한다.
          overflow: auto;
          /// Padding 영역까지 width, height 제한에 포함시킨다.
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          /// 성능 최적화
          -webkit-transform: translate3d(0, 0, 0);
          -webkit-backface-visibility: hidden;

          > .section-title {
            padding: 0.8em 1em;
            color: @black;
            background-color: @gray5;
            border-bottom: 1px solid @gray4;
            .resizableFont(1.6em);
          }
          @media (max-width: 767px) {
            > .section-title {
              padding: 0.8em 1em;
              color: @black;
              background-color: @gray5;
              border-bottom: 1px solid @gray4;
              .resizableFont(1.3em);
            }
          }
          > .section-description {
            padding: 0.8em 1em;
            color: @black;
            background-color: @gray5;
            border-bottom: 1px solid @gray4;
            .resizableFont(0.9em);
          }
          > .page-information-text {
            margin: 1em;
            padding: 0.8em 1em;
            color: @black;
            background-color: @brandLight;
            border: 1px solid @brandMain;
            .resizableFont(0.9em);
          }
          > .question {
            padding: 0 1.4em 2em;

            img {
              height: 100%;
              margin-bottom: 14px;
            }

            p {
              display: block;
              margin: 0;
              padding: 1.2em 0;
              .resizableFont(1.2em);
            }
          }
        }
      }

      input:invalid {
        box-shadow: 0 0 5px 1px red;
      }
      input:focus:invalid {
        box-shadow: none;
      }
      textarea {
        width: 100%;
        .resizableFont(16px);
        letter-spacing: 0;
        line-height: 28px;
      }
      .range-area {
        padding-top: 2em;
      }
      .radio-area, .checkbox-area {
        margin-bottom: 0.2em;
        padding-right: 0.6em;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        > div {
          background: @white;
          border: 1px solid @gray5;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          border-radius: 25px;
          height: 50px;
          max-height: fit-content;

          > div.state {
            height: 50px;
            //max-height: fit-content;
          }

          &:active {
            background: fade(@brandLight, 30%);
          }
        }
      }
    }
  }
}