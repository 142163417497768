@import "../../common/font";
@import "../../common/color";

#folder-info-modal, #normal-folder-add-patient-modal {
  width: 800px;
  height: 600px;

  .content-container {
    position: relative;
    width: 100%;
    height: 500px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: @white;

    .header-area {
      position: absolute;
      width: 100%;
      height: 60px;
      padding: 0 58px 0 52px;
      line-height: 60px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border-bottom: 1px solid @gray5;

      >.info-area {
        i {
          position: absolute;
          display: block;
          left: 20px;
          top: 21px;
        }
        h3 {
          text-align: left;
          letter-spacing: 0;
          color: @gray1;

          span {
            .fontLight();
            .resizableFont(16px);
            letter-spacing: 0;
            color: @gray3;
          }
        }
      }
      >.close-area {
        position: absolute;
        right: 20px;
        top: 22px;
        cursor: pointer;

        i {
          display: block;
        }
      }
    }
    .body-area {
      width: 100%;
      height: 100%;
      padding-top: 60px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      .folder-info-area {
        min-height: 200px;
        overflow-y: auto;

        ul.list {
          padding: 20px 12px;

          >li.row {
            position: relative;
            clear: both;

            div {
              padding: 0 8px;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              height: 30px;
              line-height: 30px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;

              &.title {
                .resizableFont(14px);
                letter-spacing: -0.4px;
                color: @gray3;
              }
              &.info {
                .resizableFont(14px);
                letter-spacing: -0.4px;
                color: @gray1;
              }
              &.multiline {
                height: initial;
              }
            }

            &.icon {
              div.info {
                padding-right: 32px;
              }
              a {
                position: absolute;
                display: block;
                top: 5px;
                right: 8px;
              }
            }
          }
        }
      }
      .filter-area {
        div.title {
          padding: 0 12px;
          height: 30px;
          line-height: 30px;
          color: @gray3;
          .resizableFont(12px);
        }
        .filter-list-area {
          width: 100%;
          padding: 10px 12px;
          .filter+.filter:before {
            position: relative;
            width: 0;
            left: -40px;
            content: 'and';
            display: inline-block;
            font-size: 12px;
            color: @gray2;
          }
          .filter+.filter {
            margin-left: 30px;
          }
          .operator {
            display: inline-block;
            font-size: 12px;
            color: @gray2;
          }
          .filter {
            display: inline-block;
            padding: 3px 12px;
            margin-bottom: 5px;
            border: solid 1px @gray4;
            border-radius: 6px;
            background-color: @white;
            h6 {
              line-height: 23px;
              span.criteria {
                .resizableFont(12px);
                .fontRegular();
              }
              span.value {
                .resizableFont(12px);
                .fontBold();
                span {
                  .resizableFont(10px);
                  color: @gray2;
                  margin: 0 2px;
                  bottom: 1px;
                  position: relative;
                }
              }
            }
            &:hover {
              cursor: pointer;
              border: solid 1px @brandMedium;
              background: fade(@brandRegular, 10%);
            }
          }
        }
      }
      .folder-list-area {
        overflow-y: auto;

        ul.folder-wrap {
          margin: 0;

          li.title {
            padding: 0 12px;
            height: 30px;
            line-height: 30px;
            color: @gray3;
            .resizableFont(12px);
          }
          li.folder {
            width: 100%;
            padding: 0 10px 6px 10px;

            h4 {
              padding-right: 16px;
            }
            i.icon {
              top: 9px;
            }
          }
        }
      }
      .patient-list-area {
        min-height: 200px;
        overflow-y: auto;

        ul.list {
          padding: 0 10px 14px 10px;

          >li {
            display: block;
            width: 100%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;

            button {
              width: 100%;
            }

            &.title {
              padding: 0 2px;
              height: 30px;
              line-height: 30px;
              color: @gray3;
              .resizableFont(12px);
            }
            &.item {
              position: relative;
              margin-bottom: 6px;
              height: 40px;
              line-height: 40px;
              -webkit-border-radius: 4px;
              -moz-border-radius: 4px;
              border-radius: 4px;
              background-color: @white;
              border: solid 1px @gray5;;

              i {
                position: absolute;
                display: block;
                vertical-align: middle;
              }
              >i {
                left: 14px;
                top: 11px;
              }
              >h4 {
                .fontLight();
                display: block;
                padding: 0 46px 0 44px;
                height: 40px;
                width: 100%;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                color: @gray1;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
              >h6 {
                .fontLight();
                display: block;
                padding: 0 46px 0 44px;
                height: 40px;
                width: 100%;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                color: @gray1;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
              > a > i {
                right: 14px;
                top: 14px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  #folder-info-modal {
    width: auto !important;
    height: auto !important;

    .content-container {
      width: auto !important;
      height: auto !important;

      .body-area {
        .folder-info-area, .patient-list-area {
          height: auto !important;
          overflow: visible;
        }
      }
    }
  }
}