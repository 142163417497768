div.patient-list-area {
  .search-area {
    .search-bar {
      display: inline-block;
    }
    .filter-add-area {
      margin-left: 10px;
      display: inline-block;
    }
    .filter-remove-area {
      margin-left: 5px;
      display: inline-block;
    }
    .smart-folder-add-area {
      margin-left: 5px;
      display: inline-block;
    }
  }
  .filter-area {
    .filter-list-area {
      &.smart-filter {
        > p {
          .fontLight();
          margin-left: 10px;
        }
      }
      .operator {
        display: inline-block;
        font-size: 12px;
        color: @gray2;
      }
      .filter+.filter:before {
        position: relative;
        width: 0;
        left: -40px;
        content: 'and';
        display: inline-block;
        font-size: 12px;
        color: @gray2;
      }
      .filter+.filter {
        margin-left: 30px;
      }
      .filter {
        display: inline-block;
        padding: 3px 12px;
        margin-bottom: 5px;
        border: solid 1px @gray4;
        border-radius: 6px;
        background-color: @white;
        h6 {
          line-height: 23px;
          span.criteria {
            .resizableFont(12px);
            .fontRegular();
          }
          span.value {
            .resizableFont(12px);
            .fontBold();
            span {
              .resizableFont(10px);
              color: @gray2;
              margin: 0 2px;
              bottom: 1px;
              position: relative;
            }
          }
        }
        .delete-button {
          position: relative;
          top: 1px;
          display: inline-block;
          margin-left: 8px;
          &:hover {
            color: @errorRed;
          }
        }
        &:hover {
          cursor: pointer;
          border: solid 1px @brandMedium;
          background: fade(@brandRegular, 10%);
        }
      }
    }
  }
  @media screen and (max-width: 1279px) {
    table {
      td:nth-last-child(2) {
        border-right: solid 1px @gray5;
        -webkit-border-top-right-radius: 5px;
        -moz-border-top-right-radius: 5px;
        border-top-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;
        -moz-border-bottom-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    .table-header-area {
      .action-area {
        text-align: left;

        .action-wrap {
          display: block;
          width: 50%;
          margin: 0;
          float: left;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;

          &:nth-child(1) {
            padding-right: 6px;
          }
          &:nth-child(2) {
            padding-left: 6px;
          }

          button {
            width: 100%;
            margin: 0;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    table {
      td:nth-last-child(3) {
        border-right: solid 1px @gray5;
        -webkit-border-top-right-radius: 5px;
        -moz-border-top-right-radius: 5px;
        border-top-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;
        -moz-border-bottom-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
  @media (max-width: 575px) {
    table {
      td:nth-last-child(4) {
        border-right: solid 1px @gray5;
        -webkit-border-top-right-radius: 5px;
        -moz-border-top-right-radius: 5px;
        border-top-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;
        -moz-border-bottom-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
}