@import "../../../common/font";
@import "../../../common/color";

div.adverse-event-area {
  .group-box {
    margin-bottom: 34px;

    > div.content {
      padding: 10px;
      ul.list {
        .resizableFont(0px);;
        >li {
          min-height: 50px;
          padding: 10px;
          >div {
            &:nth-child(1) {
              .resizableFont(14px);
              color: @gray2;
            }
            &:nth-child(2) {
              input, textarea {
                width: 100%;
              }
              textarea {
                height: 120px;
              }
            }
            .radio-area {
              .radio-wrap {
                display: inline-block;
                width: 120px;
                margin-right: 8px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                >div {
                  background: @white;
                  border: 1px solid @gray5;
                  -webkit-border-radius: 20px;
                  -moz-border-radius: 20px;
                  border-radius: 20px;
                }
                >div.radiobox {
                  height: 34px;
                  > div.state {
                    height: 34px;
                  }
                }
                >div.input-box.error {
                  border: 1px solid @errorRed;
                }
              }
            }
          }
          div.input-box.error {
            button {
              border: 1px solid @errorRed;
              margin-bottom: 18px;
            }
          }
        }
      }

      div.help-text {
        padding: 10px;
        p {
          display: block;
          .resizableFont(13px);
          color: @gray3;
        }
      }
    }

    > div.accordion {
      div.accordion-content {
        background: @white;
        ul.list {
          .resizableFont(0px);;
          > li {
            padding: 10px 0px;

            > div {
              &:nth-child(1) {
                .resizableFont(15px);
                color: @gray3;
              }

              &:nth-child(2) {
                .resizableFont(15px);
                color: @gray1
              }
            }
          }
        }
      }
    }
  }
}