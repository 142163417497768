#query-view-modal {
  .content-container {
    position: relative;
    width: 100%;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: @white;

    .header-area {
      position: absolute;
      width: 100%;
      height: 60px;
      padding: 0 58px 0 52px;
      line-height: 60px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border-bottom: 1px solid @gray5;

      >.info-area {
        i {
          position: absolute;
          display: block;
          left: 20px;
          top: 25px;
        }
        h3 {
          text-align: left;
          letter-spacing: 0;
          color: @gray1;

          span {
            .fontLight();
            .resizableFont(16px);
            letter-spacing: 0;
            color: @gray3;
          }
        }
      }
      >.close-area {
        position: absolute;
        right: 20px;
        top: 22px;
        cursor: pointer;

        i {
          display: block;
        }
      }
    }
    .body-area {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height: 600px;
      padding-top: 60px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      div.question-info-area {
        display: block;
        width: 100%;
        margin-top: 8px;
        padding: 10px 15px;
        h3 {
          display: block;
          margin-left: 5px;
          margin-top: 10px;
          font-weight: bold;
        }
        .question {
          font-weight: bold;
          color: @gray2;
        }
        .answer {
          margin-top: 5px;
        }
        div.button-area {
          position: absolute;
          width: 80px;
          right: 25px;
          button {
            &.hide {
              display: none;
            }
          }
        }
      }

      div.timeline-area {
        display: block;
        width: 100%;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        border: solid 1px @gray5;
        margin-top: 8px;
        padding: 10px 15px;
        .info-area {
          width: 100%;
          div {
            display: inline-block;
            &.action {
              display: inline-block;
              h3 {
                .fontBold();
              }
            }
            &.auditor {
              margin-left: 10px;
              color: @gray2;
              .resizableFont(11px);
            }
            &.status {
              display: inline-block;
              float: right;
              div.combobox {
                button {
                  background: #43a047;
                  padding: 0 5px;
                  border-radius: 5px;
                  h6 {
                    .resizableFont(13px);
                    margin-left: -12px;
                    text-align: center;
                    font-weight: bold;
                    color: white;
                  }
                  &:hover {
                    border-color: #43a047 !important;;
                    i {
                      background-position: 0 !important;
                    }
                  }
                }
              }
            }
          }
        }
        .log-area {
          width: 100%;
          display: inline-block;
        }
      }
      div.divider {
        border-top: 1px solid @gray4;
        width: ~"calc(100% + 20px)";
        margin: auto -10px 15px -10px;
      }
      div.comment-area {
        display: block;
        width: 100%;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        border: solid 1px @gray5;
        padding: 10px 15px;
        div.combobox, select.combobox {
          display: inline-block;
          width: 200px;
          float: left;
        }
        button.button {
          display: inline-block;
          width: 100px;
          text-align: center;
          float: right;
        }
      }
    }
  }
}
