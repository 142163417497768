// Spoqa Han Sans
@import "../library/SpoqaHanSans.less";

@import "color";

.fontBold() {
  font-weight: 700;
}
.fontRegular() {
  font-weight: 400;
}
.fontLight() {
  font-weight: 300;
}
.fontThin() {
  font-weight: 100;
}

* {
  .fontRegular();
  font-family: "Spoqa Han Sans", "Helvetica Neue", sans-serif;
  .resizableFont(14px);
  letter-spacing: -0.4px;

  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: unset;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6, p, pre {
  display: inline;
  margin: 0;
  padding: 0;
}

h1 {
  .fontBold();
  .resizableFont(24px);
  letter-spacing: 1px;
}

h2 {
  .fontBold();
  .resizableFont(20px);
  letter-spacing: 1px;
}

h3 {
  .resizableFont(16px);
  letter-spacing: 0.5px;
}

h4 {
  .resizableFont(14px);
  letter-spacing: -0.4px;
}

h5 {
  .resizableFont(13px);
  letter-spacing: -0.4px;
}

h6 {
  .resizableFont(12px);
  letter-spacing: 0;
}

pre {
  .resizableFont(14px);
  letter-spacing: -0.4px;
  line-height: 22px;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
