@import "../common/constant";
@import "../common/color";
@import "../common/font";

div.table-container {
  &.no-checkbox {
    .table-body-area table.table thead {
      th:nth-child(1) {
        padding-left: 14px;
      }
      th:nth-last-child(1) {
        padding-right: 14px;
      }
    }
    .table-body-area table.table tbody {
      td:nth-child(1) {
        padding-left: 14px;
      }
      td:nth-last-child(1) {
        padding-right: 14px;
      }
    }
  }
  .table-header-area {
    margin-bottom: 12px;

    .filter-area {
      .search-bar {
        width: 320px;
      }
      > .combobox-area {
        display: inline-block;

        .combobox-wrap {
          display: inline-block;
        }
      }
    }
    .action-area {
      text-align: right;

      button {
        margin-left: 6px;
      }
    }
  }
  .table-body-area {
    .search-summary {
      margin-top: 5px;
      color: @gray1;
      .fontLight();
      text-align: right;
      margin-right: 10px;
      .resizableFont(15px);
    }
    overflow-x: auto;
    display: block;
    width: 100%;

    table.table {
      width: 100%;
      table-layout: fixed;
      border-collapse: separate;
      border-spacing: 0 4px;
      color: @gray2;

      &.scroll-x {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
        table-layout: fixed;
        width: 100%;
      }

      &.scroll-x::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      &.scroll-x::-webkit-scrollbar:horizontal {
        height: 11px;
      }

      &.scroll-x::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white; /* should match background, can't be transparent */
        background-color: rgba(0, 0, 0, .5);
      }

      td, th {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: left;
        .resizableFont(14px);
        letter-spacing: -0.4px;

        button {
          margin: 0;
        }
      }

      thead {
        th {
          position: relative;
          height: 50px;
          padding-right: 10px;

          h4.order {
            .fontRegular();
            padding-left: 11px;
            display: block;
            width: 100%;
            padding-right: 14px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            cursor: pointer;

            &:before {
              position: absolute;
              display: block;
              top: 20px;
              left: 2px;
              width: 6px;
              height: 10px;
              background: url("@{imagePath}/icon-order-normal.png") no-repeat;
              content: ' ';
            }
            &.asc {
              .fontBold();
              color: @brandMedium;

              &:before {
                top: 22px;
                left: 0;
                width: 10px;
                height: 6px;
                background: url("@{imagePath}/icon-order-asc.png") no-repeat;
              }
            }
            &.desc {
              .fontBold();
              color: @brandMedium;

              &:before {
                top: 22px;
                left: 0;
                width: 10px;
                height: 6px;
                background: url("@{imagePath}/icon-order-desc.png") no-repeat;
              }
            }
          }

          &:nth-last-child(1) {
            padding: 0;
          }
        }
      }

      tbody {
        tr {
          height: 60px;
          margin-bottom: 4px;
          background-color: @white;
          cursor: pointer;

          &.no-path {
            cursor: default;
          }

          &.button {
            height: 50px;
            padding: 10px 0 0 0 !important;

            td {
              padding: 0 !important;
              border: none !important;
            }
          }
          td {
            .fontLight();
            border-top: solid 1px @gray5;
            border-bottom: solid 1px @gray5;
            padding-right: 10px;

            &.empty {
              text-align: center;
              pointer-events: none;
            }
            ul.presetList {
              li {
                .fontLight();
              }
            }
          }
          td:nth-child(1) {
            border-left: solid 1px @gray5;
            -webkit-border-top-left-radius: 5px;
            -moz-border-top-left-radius: 5px;
            border-top-left-radius: 5px;
            -webkit-border-bottom-left-radius: 5px;
            -moz-border-bottom-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
          td:nth-last-child(1) {
            border-right: solid 1px @gray5;
            -webkit-border-top-right-radius: 5px;
            -moz-border-top-right-radius: 5px;
            border-top-right-radius: 5px;
            -webkit-border-bottom-right-radius: 5px;
            -moz-border-bottom-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }

      &.border {
        thead {
          th {
            padding: 0 10px;
          }
        }
        tbody {
          tr {
            td {
              border-right: 1px solid @gray5;
              padding: 0 10px;
            }
          }
        }
      }
    }
  }
}
/// 2x
body.double table.table th h4.order:before {
  background-image: url("@{imagePath}/icon-order-normal@2x.png");
}
body.double table.table th h4.order.asc:before {
  background-image: url("@{imagePath}/icon-order-asc@2x.png");
}
body.double table.table th h4.order.desc:before {
  background-image: url("@{imagePath}/icon-order-desc@2x.png");
}
/// 3x
body.triple table.table th h4.order:before {
  background-image: url("@{imagePath}/icon-order-normal@3x.png");
}
body.triple table.table th h4.order.asc:before {
  background-image: url("@{imagePath}/icon-order-asc@3x.png");
}
body.triple table.table th h4.order.desc:before {
  background-image: url("@{imagePath}/icon-order-desc@3x.png");
}
/// svg
body.svg table.table th h4.order:before {
  background-image: url("@{imagePath}/icon-order-normal.svg");
}
body.svg table.table th h4.order.asc:before {
  background-image: url("@{imagePath}/icon-order-asc.svg");
}
body.svg table.table th h4.order.desc:before {
  background-image: url("@{imagePath}/icon-order-desc.svg");
}
.table-bottom-area {
  width: 100%;
  margin-top: 26px;
}