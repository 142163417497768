@import "../../common/font";
@import "../../common/color";

div.folder-graph-area {

  >div {
    margin-bottom: 40px;
  }

}
h3.folder-graph-empty-pattern {
  border: 1px solid @gray4;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: @gray2;
  padding: 20px;
  background: white;
  width: 100%;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
}