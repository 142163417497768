@import "../common/font";
@import "../common/color";

#add-smart-folder-modal {
  width: 800px;

  .content-container {
    width: 100%;

    .header-area {
      position: absolute;
      top: 0;
      width: 100%;
      height: 60px;
      padding: 0 58px 0 20px;
      line-height: 60px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border-bottom: 1px solid @gray5;

      >.info-area {
        h3 {
          text-align: left;
          letter-spacing: 0;
          color: @gray1;

          span {
            .fontLight();
            .resizableFont(16px);
            letter-spacing: 0;
            color: @gray3;
          }
        }
      }
      >.close-area {
        position: absolute;
        right: 20px;
        top: 22px;
        cursor: pointer;

        i {
          display: block;
        }
      }
    }
    .body-area {
      width: 100%;
      height: 337px;
      margin-top: 60px;
      padding-top: 8px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      overflow-y: scroll;

      > * {
        width: 100%;
      }
      .input-group {
        padding-bottom: 10px;

        label {
          color: @gray3;
        }
      }
      .input-box {
        padding: 0 10px;
        width: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        input {
          width: 100%;
        }
      }
      .combobox.combobox-base {
        button {
          width: 100%;
        }
      }
      .filter-list-area {
        padding: 0 15px;
        .template {
          display: none;
        }
        .filter+.filter:before {
          position: relative;
          width: 0;
          left: -40px;
          content: 'and';
          display: inline-block;
          font-size: 12px;
          color: @gray2;
        }
        .filter+.filter {
          margin-left: 30px;
        }
        .filter {
          display: inline-block;
          padding: 3px 12px;
          margin-bottom: 5px;
          border: solid 1px @gray4;
          border-radius: 6px;
          background-color: @white;
          h6 {
            line-height: 23px;
            span.criteria {
              .resizableFont(12px);
              .fontRegular();
            }
            span.value {
              .resizableFont(12px);
              .fontBold();
              span {
                .resizableFont(10px);
                color: @gray2;
                margin: 0 2px;
                bottom: 1px;
                position: relative;
              }
            }
          }
          .delete-button {
            position: relative;
            top: 1px;
            display: inline-block;
            margin-left: 8px;
            &:hover {
              color: @errorRed;
            }
          }
          &:hover {
            cursor: pointer;
            border: solid 1px @brandMedium;
            background: fade(@brandRegular, 10%);
          }
        }
      }
      .filter-add-area {
        padding: 0 15px;
      }
    }
    .footer-area {
      border-top: 1px solid @gray5;
      padding: 14px 10px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      >div {
        padding: 0 4px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        button {
          width: 100%;
        }
      }
    }
  }
}