@import "../../../common/constant";
@import "../../../common/color";
@import "../../../common/font";

#activate {
  width: 100%;
  height: 100%;
  background: @black;

  @media (max-width: 1023px) {
    background: transparent;

    >.activate-container-wrap {
      >.form-area > .form-wrap {
        background: transparent !important;
      }
    }
  }

  >.activate-container-wrap {
    position: relative;
    text-align: center;

    @media (min-width: 1024px) {
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    >.header-area {
      background: @black;
      height: 44px;

      i {
        margin-top: 12px;
      }
    }
    >.form-area {
      display: inline-block;
      width: 320px;
      height: 480px;

      .form-wrap {
        padding: 54px 20px;
        background: @white;
        text-align: left;

        .header-area {
          padding-bottom: 42px;

          .image {
            text-align: center;
          }
          .information {
            padding-top: 24px;
            text-align: center;
            height: 40px;
            line-height: 40px;

            &.big {
              h1 {
                .resizableFont(36px);
              }
              &:after {
                margin-top: 10px;
              }
            }

            h1 {
              .fontBold();
              .resizableFont(24px);
              letter-spacing: 1.3px;
              text-align: center;
              color: @black;
            }

            &:after {
              position: relative;
              display: block;
              margin: 0 auto;
              width: 34px;
              height: 2px;
              background-color: @black;
              content: ' ';
            }
          }
        }
        .content-area {
          .input-group {
            padding-bottom: 24px;
          }
          .button-area {
            margin: 0 -6px;
            padding-top: 16px;

            .button-wrap {
              padding: 0 6px;

              button {
                width: 100%;
              }
            }
          }
          .desc-group {
            padding-bottom: 24px;
            text-align: center;

            h6 {
              .fontLight();
              color: @gray2;
            }
          }
        }
      }
    }
  }
}