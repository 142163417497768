@import "../../common/font";
@import "../../common/color";

.case-statistics-area {
  .group-box {
    margin-bottom: 40px;
  }
  >div {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    &:nth-child(odd) {
      padding-right: 10px;
    }
    &:nth-child(even) {
      padding-left: 10px;
    }
  }
}