@import '../common/color';

svg.line-graph {
  .axis {
    .tick > text {
      .resizableFont(12px);
      fill: @gray3;
    }
    &.y {
      .domain {
        display: none;
      }
      .tick > text {
        text-anchor: start;
      }
    }
  }
}
#graph-gradient {
  .stop1 {
    stop-color: @brandLight;
    stop-opacity: 0.4;
  }
  .stop2 {
    stop-color: @brandLight;
  }
}

body.ie9 .pie-graph > .legend-area > ul.list {
  top: inherit;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}

.pie-graph {
  >.graph-area {
    path.arc {
      stroke: #fff;
    }
  }
  >.legend-area {
    position: relative;

    >ul.list {
      position: absolute;
      top: 50%;
      width: 100%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);

      > li.row {
        position: relative;
        margin: 4px 0;
        clear: both;
        background-color: @white;
        border: solid 1px @gray5;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;

        div {
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          height: 40px;
          line-height: 40px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          .resizableFont(14px);
          letter-spacing: -0.4px;

          &.icon {
            position: absolute;
            top: 16px;
            left: 14px;
            margin: 0;
            padding: 0;
            width: 8px;
            height: 8px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
          }
          &.title {
            margin: 0 74px 0 30px;
            color: @gray3;
          }
          &.percent {
            position: absolute;
            top: 0;
            right: 14px;
            margin: 0;
            padding: 0;
            width: 50px;
            color: @gray1;
            text-align: right;
          }
        }

        &.icon {
          div.info {
            padding-right: 32px;
          }
          a {
            position: absolute;
            display: block;
            top: 5px;
            right: 8px;
          }
        }
      }
    }
  }
}