@import "../../../common/constant";
@import "../../../common/color";
@import "../../../common/font";

#login {
  width: 100%;
  height: 100%;
  background: @black;

  @media (max-width: 1023px) {
    background: transparent;

    >.login-container-wrap {
      >.form-area > .form-wrap {
        background: transparent !important;
      }
    }
  }

  >.login-container-wrap {
    position: relative;
    text-align: center;

    @media (min-width: 1024px) {
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);

      > .information-area + .form-area {
        width: 370px;
        .form-wrap {
          margin-left: 50px;
        }
      }
    }

    >.header-area {
      background: @black;
      height: 44px;

      i {
        margin-top: 12px;
      }
    }

    >.information-area {
      position: relative;
      display: inline-block;
      width: 512px;
      height: 530px;
      vertical-align: top;

      .information-wrap {
        width: 100%;
        position: absolute;
        margin-right: 50px;
        top: 50%;
        left: 0;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        text-align: left;

        .divider {
          border-top: 1px solid @gray2;
          width: 100%;
          margin: 30px auto;
        }

        .content-area {
          padding: 10px 0 0 42px;

          .content-wrap {
            h1 {
              display: block;
              .resizableFont(24px);
              line-height: 1.39;
              letter-spacing: 1px;
              color: @white;
              span.highlight {
                .fontBold();
                .resizableFont(24px);
                line-height: 1.39;
                letter-spacing: 1px;
                color: @brandMedium;
              }
            }
            h3 {
              display: block;
              margin-top: 20px;
              text-align: left;
              color: @gray4;
              span.highlight {
                .resizableFont(16px);
                letter-spacing: 0.5px;
                color: @brandMedium;
              }
            }
            h4.contact {
              float: right;
              position: relative;
              top: 50px;
              color: @gray3;
              a {
                .resizableFont(14px);
                color: @gray3;
              }
            }
          }
        }
      }
    }
    >.form-area {
      display: inline-block;
      width: 320px;
      height: 530px;

      .form-wrap {
        padding: 54px 20px;
        background: @white;
        text-align: left;

        .header-area {
          padding-bottom: 42px;

          .image {
            text-align: center;
          }
          .information {
            padding-top: 24px;
            text-align: center;
            height: 40px;
            line-height: 40px;

            h1 {
              .fontBold();
              .resizableFont(24px);
              letter-spacing: 1.3px;
              text-align: center;
              color: @black;
            }

            &:after {
              position: relative;
              display: block;
              margin: 0 auto;
              width: 34px;
              height: 2px;
              background-color: @black;
              content: ' ';
            }
          }
        }
        .content-area {
          .input-group {
            padding-bottom: 24px;
          }
          .button-area {
            margin: 0 -6px;
            padding-top: 16px;

            .button-wrap {
              padding: 0 6px;

              button {
                width: 100%;
              }
            }
          }
          .desc-group {
            padding-top: 12px;
            text-align: center;

            h6 {
              .fontLight();
              text-decoration: underline;
              color: @gray3;
            }
          }
        }
      }
    }
  }
}