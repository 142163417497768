.case-result-area {
  div.group-box {
    padding: 15px;
  }
  div.accordion {
    > h4 {
      position: relative;
      display: block;
      padding: 0 2px 2px;
      width: 100%;
      height: 34px;
      line-height: 34px;
      .resizableFont(15px);
      text-align: left;
      color: @gray3;
      background: @gray6;
      z-index: 1;
    }
    .accordion-content {
      .resizableFont(0px);
      .group-box {
        > h4 {
          background: white;
        }
      }
    }
    width: 100%;
    margin: 0;
  }
}