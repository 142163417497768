@import "../common/font";
@import "../common/color";

div.error-container {
  width: 100%;
  height: 100%;
  background: @black;

  .error-area {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 300px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    .error-wrap {
      text-align: center;

      h1 {
        .fontLight();
        display: block;
        .resizableFont(130px);
        letter-spacing: 30px;
        margin-right: -30px;
        color: @gray4;
      }
      h2 {
        display: block;
        .resizableFont(26px);
        color: @gray3;
      }
      .slice {
        display: block;
        margin: 30px auto;
        width: 34px;
        height: 2px;
        background: @gray3;
      }
      h3, a {
        .fontLight();
        display: block;
        color: @gray3;
      }

      @media (max-width: 767px) {
        h1 {
          .fontLight();
          .resizableFont(100px);
          letter-spacing: 20px;
          margin-right: -20px;
        }
        h2 {
          .resizableFont(18px);
        }
        h3, a {
          .resizableFont(14px);
        }
      }
    }
  }
}