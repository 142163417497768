@yes: #43a047;
@no: @errorRed;
div.participant-protocol-area {
  div.questionnaire-schedule-area.content {
    padding: 0 10px 10px 10px;

    table tbody, thead {
      tr {
        cursor: default;
        th, td {
          text-align: center;
          div.protocol-status{
            cursor: pointer;
            p {
              padding: 5px 10px;
              border-radius: 30px;
              color: white;
              .resizableFont(15px);
              font-weight: 600;
              &.yes {
                border: @yes;
                background: @yes;
              }
              &.no {
                border: @no;
                background: @no;
              }
            }
          }
        }
      }
    }
  }
}