div.ecrf-form-area {

  .ecrf-form-wrap {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    .upsert-button {
      display: block;
      text-align: center;
    }
  }

  li.image {
    .input-box {
      .upload-area {
        button {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          &:disabled {
            pointer-events: none;
          }
        }

        img.preview {
          max-height: 180px;
          position: relative;
          left: 150px;
        }
      }
    }

    div.answer-box {
      img {
        max-width: 800px;
        max-height: 800px;
      }
    }
  }

  .group-box {
    margin-bottom: 34px;

    > div.content {
      padding: 10px;

      ul.list {
        .resizableFont(0px);;

        li {
          &.dense {
            display: inline-block;
            min-width: 300px;
            @media (min-width: 1280px) {
              width: 33.3%;
            }
            @media (max-width: 1279px) {
              width: 50%;
            }
            @media (max-width: 767px) {
              width: 100%;
            }
          }

          > div {
            display: inline-flex;
            flex-direction: column;
            align-content: center;
            justify-content: start;
            min-height: 50px;
            padding: 10px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;

            span.error {
              line-height: 20px;
            }

            span.help-text {
              position: absolute;
              width: 100%;
              text-align: center;
              line-height: 20px;
              display: block;
              .resizableFont(10px);
              color: @errorRed;
            }

            &:nth-child(1) {
              .resizableFont(14px);
              color: @gray3;

              > p.description {
                margin-top: 5px;
                .resizableFont(11px);
              }
            }

            &:nth-child(2) {
              textarea {
                width: 100%;
                height: 120px;
              }
            }

            &:nth-child(3) {
              .resizableFont(14px);
              color: @gray3;

              > p.description {
                margin-top: 5px;
                .resizableFont(11px);
              }
            }

            .radio-area {
              margin-bottom: -20px;

              .radio-wrap {
                min-width: 152px;
                display: inline-block;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                margin-bottom: 20px;

                > div {
                  background: @white;
                  border: 1px solid @gray5;
                  -webkit-border-radius: 20px;
                  -moz-border-radius: 20px;
                  border-radius: 20px;
                }

                > div.radiobox {
                  height: 34px;

                  > div.state {
                    height: 34px;
                  }
                }

                > div.input-box.error {
                  border: 1px solid @errorRed;
                }
              }
            }

            .checkbox-area {
              margin-bottom: -20px;

              .checkbox-wrap {
                min-width: 152px;
                display: inline-block;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                margin-bottom: 20px;

                > div {
                  background: @white;
                  border: 1px solid @gray5;
                  -webkit-border-radius: 20px;
                  -moz-border-radius: 20px;
                  border-radius: 20px;
                }

                > div.checkbox {
                  height: 34px;

                  > div.state {
                    height: 34px;
                    display: table;

                    > label {
                      display: table-cell;
                      vertical-align: middle;
                      line-height: normal;
                      color: @gray3;
                      overflow: hidden;
                    }
                  }
                }

                > div.input-box.error {
                  border: 1px solid @errorRed;
                }
              }
            }

            div.input-box.error {
              button {
                border: 1px solid @errorRed;
                margin-bottom: 18px;
              }
            }
          }

          .answer-box {
            .resizableFont(14px);
            color: @gray1;
          }
        }
      }

      div.rich-form-container {
        > p {
          .resizableFont(14px);
          color: #a5a7a8;
        }

        > p.description {
          margin-top: 5px;
          .resizableFont(11px);
        }

        margin: 10px;
        display: inline-block;

        table {
          th {
            > p {
              .resizableFont(14px);
              color: #a5a7a8;
            }
            > p.description {
              margin-top: 5px;
              .resizableFont(11px);
            }
          }
          td {
            cursor: default;
            padding-top: 15px;
            padding-bottom: 15px;

            &.delete-row {
              text-align: center;
              cursor: pointer;

              &.disabled {
                pointer-events: none;
              }

              .fa-times {
                color: @errorRed;
              }
            }

            &.read {
              position: relative;
            }

            .radio-area {
              margin-bottom: -20px;
              .radio-wrap {
                display: inline-block;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                margin-bottom: 15px;

                > div {
                  background: @white;
                  border: 1px solid @gray5;
                  -webkit-border-radius: 20px;
                  -moz-border-radius: 20px;
                  border-radius: 20px;
                }

                > div.radiobox {
                  height: 34px;

                  > div.state {
                    height: 34px;
                  }
                }

                > div.input-box.error {
                  border: 1px solid @errorRed;
                }
              }
              .reset-radio {
                cursor: pointer;
                position: relative;
                display: inline-block;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                bottom: 26px;
                input {
                  cursor: pointer;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  opacity: 0;
                  z-index: 1;
                }
                label {
                  color: @errorRed;
                  background: #ffffff;
                  border: 1px solid #eeeeee;
                  -webkit-border-radius: 20px;
                  -moz-border-radius: 20px;
                  border-radius: 20px;
                  padding: 5px 10px;
                  i {
                    height: 15px;
                    width: 15px;
                    text-align: center;
                  }
                }
              }
            }

            .rich-question {
              &.multi-text {
                .multi-input {
                  display: inline-block;

                  .input-box {
                    display: inline-block;

                    input {
                      min-width: 35px;
                      max-width: 70px;
                    }

                    span {
                      white-space: nowrap;
                    }
                  }
                }
              }

              &.dropdown-select {
                .input-box {
                  width: 100%;
                }
              }
            }

            .question.text {
              input {
                width: 100%;
              }
            }
          }
        }

        div.add-row {
          margin: 5px 0;
          text-align: center;
          cursor: pointer;
          &.disabled {
            pointer-events: none;
          }

          i {
            color: @gray2;
            .resizableFont(20px);
          }
        }
      }
    }

    &.form-group {
      .question {
        &.read {
          position: relative;
        }

        &.date {
          .input-box {
            width: 150px;
          }
        }

        &.select-with-text {
          .text-area {
            margin-bottom: 6px;
            > input {
              width: 100%;
            }
          }
          .extra-text {
            display: flex;
            justify-content: flex-end;
          }
        }

        &.numeric, &.multi-text {
          input {
            text-align: right;
          }
        }

        &.multi-text {
          .multi-input {
            display: inline-block;

            .input-box {
              display: inline-block;

              input {
                min-width: 35px;
              }

              span {
                white-space: nowrap;
              }

              span.error {
                margin-top: 45px;
              }
            }
          }
        }

        span.postfix {
          color: @black;
          margin: 0 5px;
        }

        > div.stratified-form:after {
          content: "(랜덤의 기준이 되는 필드로 수정이 불가합니다.)";
          color: @errorRed;
          display: block;
          .resizableFont(11px);
          line-height: 12px;
        }
      }
    }

    &.form-group {
      &.disable-query {
        .query-alert {
          display: none !important;
        }
        .query-button {
          display: none !important;
        }
      }
      .question.read {
        &.query-opened {
          .query-alert {
            opacity: 1;
          }
        }

        &:hover {
          .query-button {
            opacity: 1;

            i:hover {
              background: @gray4;
            }
          }
        }

        .query-button {
          cursor: pointer;
          opacity: 0;
          top: 0;
          right: 10px;
          position: absolute;
          -webkit-transition: all @animationNormal;
          -moz-transition: all @animationNormal;
          -ms-transition: all @animationNormal;
          -o-transition: all @animationNormal;
          transition: all @animationNormal;

          i {
            background: @gray5;
            padding: 8px;
            border-radius: 30px;
            color: @brandMedium;
            .resizableFont(22px);
          }
        }

        .query-alert {
          opacity: 0;
          right: 55px;
          position: absolute;

          i {
            padding: 8px;
            color: @errorRed;
            .resizableFont(22px);
          }
        }
      }

      div.rich-form-area {
        table td.read {
          &.query-opened {
            .query-alert {
              opacity: 1;
            }
          }
          &:hover {
            .query-button {
              opacity: 1;
              i:hover {
                background: @gray4;
              }
            }
          }
          .query-button {
            cursor: pointer;
            opacity: 0;
            top: 3px;
            right: 3px;
            position: absolute;
            -webkit-transition: all @animationNormal;
            -moz-transition: all @animationNormal;
            -ms-transition: all @animationNormal;
            -o-transition: all @animationNormal;
            transition: all @animationNormal;
            i {
              background: @gray5;
              padding: 5px;
              border-radius: 30px;
              color: @brandMedium;
              .resizableFont(17px);
            }
          }
          .query-alert {
            opacity: 0;
            top: 3px;
            right: 5px;
            position: absolute;
            i {
              padding: 5px;
              color: @errorRed;
              .resizableFont(17px);
            }
          }
        }
      }
    }
  }
}