@import "../common/color";

div.progress {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 8px;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;

    &.percent {
      background-color: @brandMedium;
      z-index: 1;
    }
    &.body {
      width: 100%;
      background-color: @gray5;
      z-index: 0;
    }
  }
}