@import "../common/font";
@import "../common/color";

.ecrf-landing-info {
  div.content-container {
    div.header-area {
      padding-top: 70px;

      h1.title {
        display: block;
        width: 100%;
        line-height: 50px;
        padding: 0 20px;
        margin-bottom: 6px;
        text-align: center;
        color: @gray1;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }

      h1.desc {
        font-weight: 300;
        display: block;
        width: 100%;
        min-height: 32px;
        padding: 0 20px;
        margin-bottom: 6px;
        text-align: center;
        color: @gray1;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        span.highlight {
          .resizableFont(24px);
          color: @brandMedium;
        }
      }
      > .prev-area {
        position: absolute;
        top: 14px;
        left: 20px;
      }
      > .close-area {
        position: absolute;
        right: 20px;
        top: 22px;
        cursor: pointer;

        i {
          display: block;
        }
      }
    }

    div.body-area {
      > div {
        > div {
          margin-top: 10px;
        }

        .text-box {
          display: flex;
          align-items: center; /* align vertical */
          p {
            font-weight: 300;
            word-break : keep-all;
            display: block;
            .resizableFont(16px);
            padding: 0 20px;
            color: @black;
            a {
              cursor: pointer;
              color: @brandMedium;
            }
          }
        }

        img.institute-logo {
          width: 150px;
          margin: 0 auto;
        }
      }

      #start-btn {
        width: 200px;
        height: 45px;
        text-align: center;
        display: block;
        margin: 100px auto;

        > h6 {
          .resizableFont(17px);
        }
      }
    }

    div.footer-area {
      div.contact {
        position: absolute;
        right: 20px;
        bottom: 22px;

        > * {
          display: block;
        }

        p.title {
          color: @black;
          text-align: right;
          .resizableFont(17px);
        }
        p.desc {
          color: @gray1;
          .resizableFont(15px);
          font-weight: 300;
          span.highlight {
            font-weight: 300;
            color: @brandMedium;
          }
        }

        a {
          position: relative;
          z-index: 10;
          color: @brandMedium;
        }
      }
    }
  }
}