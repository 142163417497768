@import "../common/constant";
@import "../common/font";
@import "../common/color";

#profile-popup {
  position: absolute;
  display: none;
  opacity: 0;
  top: 60px;
  width: 140px;
  margin-top: 10px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: all @animationNormal;
  -moz-transition: all @animationNormal;
  -ms-transition: all @animationNormal;
  -o-transition: all @animationNormal;
  transition: all @animationNormal;
  -webkit-box-shadow: 0 1px 4px 0 @shadow1;
  -moz-box-shadow: 0 1px 4px 0 @shadow1;
  box-shadow: 0 1px 4px 0 @shadow1;
  background-color: @white;
  z-index: 101;

  .profile-popup-area {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    overflow: auto;

    .body-area {
      ul.list {
        li {
          position: relative;

          &.item {
            width: 100%;
            padding: 12px 12px 12px 12px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            border-bottom: 1px solid @gray5;
            cursor: pointer;

            h4 {
              .fontLight();
              color: @gray2;

              >b {
                .fontRegular();
              }
            }
            &:hover {
              background: @gray6;
            }
          }
        }
        &:nth-last-child(1) {
          border-bottom: 0;
        }
      }
    }
  }

  &:before {
    position: absolute;
    top: -7px;
    left: 50%;
    content: ' ';
    width: 0;
    height: 0;
    border-left: 7.5px solid transparent;
    border-right: 7.5px solid transparent;
    border-bottom: 7px solid @white;
    -webkit-transform: translate3d(-50%, 0, 0);
    -moz-transform: translate3d(-50%, 0, 0);
    -ms-transform: translate3d(-50%, 0, 0);
    -o-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
    z-index: 2;
  }
  &:after {
    position: absolute;
    top: -9px;
    left: 50%;
    content: ' ';
    width: 0;
    height: 0;
    border-left: 9.5px solid transparent;
    border-right: 9.5px solid transparent;
    border-bottom: 9px solid rgba(0, 0, 0, 0.05);
    -webkit-transform: translate3d(-50%, 0, 0);
    -moz-transform: translate3d(-50%, 0, 0);
    -ms-transform: translate3d(-50%, 0, 0);
    -o-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
    z-index: 1;
  }
  &.show {
    margin: 0;
    opacity: 1;
  }
}
@media (max-width: 1023px) {
  #profile-popup {
    position: fixed;
    left: 10px !important;
    right: 10px;
    top: 56px;
    width: auto;

    &:before {
      left: initial;
      right: 0;
    }
    &:after {
      left: initial;
      right: -4px;
    }
  }
}