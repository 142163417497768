@import "../common/constant";
@import "../common/color";

textarea::-webkit-input-placeholder {
  color: @gray4;
}
textarea:-moz-placeholder {
  /* FF 4-18 */
  color: @gray4;
}
textarea::-moz-placeholder {
  /* FF 19+ */
  color: @gray4;
}
textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: @gray4;
}
textarea::-ms-input-placeholder {
  /* Microsoft Edge */
  color: @gray4;
}

textarea.textarea-placeholder {
  color: @gray4;
}
textarea {
  margin: 0;
  padding: 12px;
  width: 100%;
  height: 100%;
  color: @gray2;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: solid 1px @gray5;
  background-color: @white;
  outline: none;
  .resizableFont(14px);
  letter-spacing: -0.4px;
  text-align: left;
  -webkit-transition: all @animationFast;
  -moz-transition: all @animationFast;
  -ms-transition: all @animationFast;
  -o-transition: all @animationFast;
  transition: all @animationFast;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: vertical;

  &:focus {
    border-color: @gray3;
  }

  &:disabled, &[readonly] {
    background-color: @gray5;
    color: @gray3;
  }
}